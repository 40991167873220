import { hasValue } from 'src/packages/utils/has-value';

import type { TEnableIfRule } from '../mappers';
import type { ObservableMap } from 'mobx';
import type { IControl } from 'src/services/directory-service/types';

export const handleEnableIfRule = (
  controlsMap: ObservableMap<string, IControl<unknown>>,
  rule: TEnableIfRule,
): { isDisabled: boolean } => {
  let isDisabled = false;

  for (const cond of rule.ruleView) {
    if (isDisabled) {
      break;
    }

    const managControl = controlsMap.get(cond.control);

    if (!managControl) {
      continue;
    }

    if (cond.value === '!null') {
      isDisabled = !hasValue(managControl.value);
      continue;
    }

    isDisabled = managControl.value !== cond.value;
  }

  return { isDisabled };
};
