import { InputNumber } from '@profgeosoft-ui/react';
import { useTranslation } from 'react-i18next';

import { wrap } from 'src/packages/mobx-di/wrap';
import { hasValue } from 'src/packages/utils/has-value';

import type { MutableRefObject } from 'react';
import type { NumberField } from 'src/services/directory-service/entities/controls/number-field';
import type { IControl } from 'src/services/directory-service/types';

type Props = {
  control: NumberField;
  disabled?: boolean;
  isLoading?: boolean;
  className?: string;
  innerRef?: MutableRefObject<HTMLInputElement | null>;
  onChange(control: IControl, value: unknown): void;
  onBlur?(control: IControl): void;
};

export const NumberInput = wrap<Props>(function NumberInput({ innerRef, control, className, onChange, onBlur }) {
  const { t } = useTranslation();

  const handleChange = (value: string | number) => {
    if (!hasValue(value) || value === '') {
      onChange(control, null);
      return;
    }

    if (typeof value === 'number') {
      onChange(control, value);
      return;
    }

    const newValue = Number(value);

    if (!Number.isNaN(newValue)) {
      onChange(control, newValue);
    } else {
      onChange(control, null);
    }
  };

  const handleBlur = () => {
    onBlur?.(control);
  };

  return (
    <InputNumber
      ref={innerRef}
      className={className}
      value={control.value ?? ''}
      loading={control.isLoading}
      status={control.error ? 'error' : undefined}
      placeholder={t('common:placeholders.enterValue')}
      disabled={control.isDisabled || control.isEnteringBlocked}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
});
