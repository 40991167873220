import { throwApiError } from 'src/packages/errors';

import type { AxiosInstance } from 'axios';

export class I18Api {
  private readonly agent: AxiosInstance;

  constructor(agent: AxiosInstance) {
    this.agent = agent;
  }

  async fetchSettingsLabels(
    languages: string[],
  ): Promise<{ key: string; value: Record<string, Record<string, string>> }[]> {
    try {
      const { data } = await this.agent.get('settings/labels/list', {
        params: {
          languages: languages.join(','),
        },
      });

      return data;
    } catch (e) {
      throwApiError(e);
    }
  }
}
