import type { TCalculatedValueRule } from '../mappers';
import type { ObservableMap } from 'mobx';
import type { IDirectoriesStorage } from 'src/services/directories-storage-service/directories-storage-service';
import type { IControl } from 'src/services/directory-service/types';

import { handleValueSourceRule } from './handle-value-source-rule';

export const handleCalculatedValueRule = (
  directories: IDirectoriesStorage,
  controlsMap: ObservableMap<string, IControl>,
  rule: TCalculatedValueRule,
): { value: unknown } => {
  const { valuesByPseudonims } = handleValueSourceRule(directories, controlsMap, rule.ruleView.valueSource.sources);

  const args = Object.keys(valuesByPseudonims).join(', ');
  const values = Object.values(valuesByPseudonims);

  const getValue = new Function(args, rule.ruleView.body);

  const calculatedValue: unknown = getValue(...values);

  return { value: calculatedValue };
};
