import { action, makeObservable, observable } from 'mobx';

import { hasValue } from 'src/packages/utils/has-value';

import type { TRule } from './mappers';
import type { IDirectoriesStorage } from '../directories-storage-service/directories-storage-service';
import type { IControl } from '../directory-service/types';
import type { TControlView } from '../directory-service/types/controls-views.type';
import type { ValidationService } from '../directory-service/validation-service';
import type { ObservableMap } from 'mobx';

import { CheckboxField } from '../directory-service/entities/controls/checkbox-field';
import { ComboboxField } from '../directory-service/entities/controls/combobox-field';
import { LabelField } from '../directory-service/entities/controls/label-field';
import { MultiComboboxField } from '../directory-service/entities/controls/multicombobox-field';

import { mapControlRules } from './mappers';
import { handleCalculatedValueRule } from './rule-handlers/handle-calculated-value-rule';
import { handleConcatinationRule } from './rule-handlers/handle-concatination-rule';
import { handleEnableIfRule } from './rule-handlers/handle-enable-if-rule';
import { handleFilterOptionsRule } from './rule-handlers/handle-filter-options-rule';
import { handleRequiredIfRule } from './rule-handlers/handle-required-if-rule';
import { handleRestrictionsRule } from './rule-handlers/handle-restrictions-rule';
import { handleValueSourceRule } from './rule-handlers/handle-value-source-rule';

export class ControlRulesService {
  private readonly directoriesStorageService: IDirectoriesStorage;
  private readonly validationService: ValidationService;
  readonly rules = observable.map<string, TRule[]>();

  constructor(directoriesStorageService: IDirectoriesStorage, validationService: ValidationService) {
    this.directoriesStorageService = directoriesStorageService;
    this.validationService = validationService;

    makeObservable(this);
  }

  @action.bound
  mapRulesFromControlViews(controls: TControlView[]): void {
    for (const control of controls) {
      const rules = mapControlRules(control);

      for (const { managmentControls, rule } of rules) {
        for (const managmentControl of managmentControls) {
          const rules: TRule[] = [...(this.rules.get(managmentControl) ?? []), rule];

          this.rules.set(managmentControl, rules);
        }
      }
    }
  }

  @action.bound
  onControlValueChange(
    controlsMap: ObservableMap<string, IControl<unknown>>,
    fieldId: string,
    value: unknown,
    onControlValueChange: (control: IControl, value: unknown) => void,
  ): void {
    const rules = this.rules.get(fieldId);

    if (!rules || !rules.length) {
      return;
    }

    rules.forEach((rule) => {
      switch (rule.type) {
        case 'valueSource': {
          const dependentControl = controlsMap.get(rule.dependentField ?? '');

          if (!dependentControl) {
            console.error(`cannot find corresponding dependent control. fieldId: ${rule.dependentField}`);
            break;
          }

          const { value } = handleValueSourceRule(this.directoriesStorageService, controlsMap, rule.ruleView.sources);

          onControlValueChange(dependentControl, value);

          break;
        }

        case 'restrictions': {
          const control = controlsMap.get(fieldId);

          if (!control) {
            console.error(`cannot find corresponding control. fieldId: ${fieldId}`);
            break;
          }

          const data = handleRestrictionsRule(control, rule, value);

          if (data) {
            onControlValueChange(control, data.value);
          }

          break;
        }

        case 'enableIf': {
          const depControl = controlsMap.get(rule.dependentField);

          if (!depControl) {
            console.error(`cannot find corresponding control. fieldId: ${fieldId}`);
            break;
          }

          const { isDisabled } = handleEnableIfRule(controlsMap, rule);

          depControl.setIsDisabled(isDisabled);

          if (isDisabled) {
            onControlValueChange(depControl, null);
          }

          break;
        }

        case 'filterOptions': {
          const depControl = controlsMap.get(rule.dependentField);

          if (!depControl) {
            console.error(`cannot find corresponding control. fieldId: ${fieldId}`);
            break;
          }

          if (!(depControl instanceof ComboboxField) && !(depControl instanceof MultiComboboxField)) {
            break;
          }

          const { allowedOptions } = handleFilterOptionsRule(
            this.directoriesStorageService,
            controlsMap,
            depControl,
            rule,
            value,
          );

          depControl.setAllowedOptions(allowedOptions);

          if (
            depControl instanceof ComboboxField &&
            !!allowedOptions &&
            hasValue(depControl.value) &&
            !allowedOptions[depControl.value]
          ) {
            onControlValueChange(depControl, null);
          }

          if (depControl instanceof MultiComboboxField && !!allowedOptions && !!depControl.value.length) {
            const filteredValue = depControl.value.filter((v) => allowedOptions[v]);

            if (filteredValue.length !== depControl.value.length) {
              onControlValueChange(depControl, filteredValue);
            }
          }

          break;
        }

        case 'concatination': {
          const depControl = controlsMap.get(rule.dependentField);

          if (!depControl || !(depControl instanceof LabelField)) {
            console.error(`cannot find corresponding control. fieldId: ${fieldId}`);
            break;
          }

          const { value } = handleConcatinationRule(this.directoriesStorageService, controlsMap, rule);

          if (depControl.view.concatination?.concatinationType === 'display') {
            depControl.setDisplayValue(value);
          } else {
            onControlValueChange(depControl, value);
          }

          break;
        }

        case 'calculatedValue': {
          const depControl = controlsMap.get(rule.dependentField);

          if (!depControl) {
            console.error(`cannot find corresponding control. fieldId: ${fieldId}`);
            break;
          }

          const { value } = handleCalculatedValueRule(this.directoriesStorageService, controlsMap, rule);

          if (depControl instanceof CheckboxField) {
            if (!!value !== depControl.value) {
              onControlValueChange(depControl, value);
            }

            break;
          }

          onControlValueChange(depControl, value);

          break;
        }

        case 'requiredIf': {
          const depControl = controlsMap.get(rule.dependentField);

          if (!depControl) {
            console.error(`cannot find corresponding control. fieldId: ${fieldId}`);
            break;
          }

          const { isRequired } = handleRequiredIfRule(this.directoriesStorageService, controlsMap, depControl, rule);
          this.validationService.setIsControlRequired(depControl.id, isRequired);
        }
      }
    });
  }
}
