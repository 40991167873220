import { requireService } from '@profgeosoft/di-ez';
import { Search } from '@profgeosoft-ui/icons';
import { Button, CollapseSidebar, Input } from '@profgeosoft-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { wrap } from 'src/packages/mobx-di/wrap';

import type { ChangeEvent } from 'react';
import type { TDirectory, TDirectoryGroup } from 'src/services/directories-list-service/directories-list-service';

import DirectoriesCollapse from './directories-collapse/directories-collapse';
import FilterType from './filter-type/filter-type';

import styles from './directories-list.module.scss';

export const DirectoriesList = wrap(function DirectoriesList() {
  const { directory, subDirectory } = useParams();
  const navigate = useNavigate();
  const directoriesListService = requireService('directoriesListService');
  const { isDirectoryChangingBlocked } = directoriesListService;

  const { t } = useTranslation('directoriesList');

  const [isDirectoryListOpen, setDirectoryListOpen] = useState(true);
  const [collapseControl, setCollapseControl] = useState<Record<string, boolean>>({});
  const [searchValue, setSearchValue] = useState('');

  const groups =
    directoriesListService.filterType === 'all'
      ? directoriesListService.directoriesByGroups
      : directoriesListService.groupedFavoriteDirectories;

  const filteredDirectories = useMemo((): TDirectoryGroup[] => {
    if (searchValue === '') {
      return groups;
    }

    const searchResult: TDirectoryGroup[] = [];

    groups.forEach((group) => {
      const filteredDirectories = group.items.filter((item) => {
        const valueLabel = t(`labels:${item.title}.label`);

        return valueLabel.toLowerCase().includes(searchValue.toLowerCase());
      });

      if (filteredDirectories.length > 0) {
        searchResult.push({ ...group, items: filteredDirectories });
      }
    });

    return searchResult;
  }, [groups, searchValue, t]);

  const currentDirectoryNameKey = directoriesListService.currentDirectoryView?.title;

  const toggleCollapseOpen = useCallback((id: string) => {
    setCollapseControl((prev) => ({ ...prev, [id]: !prev[id] }));
  }, []);

  const controlAllCollapse = (value: boolean) => {
    setCollapseControl((prev) => {
      const updatedControl: Record<string, boolean> = {};
      for (const collapseId in prev) {
        updatedControl[collapseId] = value;
      }

      return updatedControl;
    });
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setSearchValue(value);

    if (value) {
      controlAllCollapse(true);
    }
  };

  useEffect(() => {
    directoriesListService.init();
  }, [directoriesListService]);

  useEffect(() => {
    const emptyCollapseControl: Record<string, boolean> = {};
    directoriesListService.directoriesByGroups?.forEach((item) => (emptyCollapseControl[item.title] = true));

    setCollapseControl(emptyCollapseControl);
  }, [directoriesListService.directoriesByGroups]);

  useEffect(() => {
    if (!directoriesListService.directoriesByGroups.length || !directory) {
      directoriesListService.setCurrentDirectory(null);
      directoriesListService.setCurrentSubDirectory(null);
      return;
    }

    const directoryObject = ((): TDirectory | null => {
      for (const group of directoriesListService.directoriesByGroups) {
        for (const item of group.items) {
          if (item.objectType !== directory) {
            continue;
          }

          return item;
        }
      }

      return null;
    })();

    if (!!directoryObject) {
      directoriesListService.setCurrentDirectory(directory);
    }

    if (!directoryObject && directoriesListService.directoriesByGroups) {
      directoriesListService.setCurrentDirectory(null);
      directoriesListService.setCurrentSubDirectory(null);
      navigate('/');
      return;
    }

    if (!subDirectory) {
      directoriesListService.setCurrentSubDirectory(null);
      return;
    }

    const subDirectoryObject = directoryObject?.items?.find((item) => item.objectType === subDirectory);

    if (subDirectoryObject) {
      directoriesListService.setCurrentSubDirectory(subDirectory);
      return;
    }

    if (!subDirectoryObject && directoriesListService.directoriesByGroups) {
      directoriesListService.setCurrentSubDirectory(null);
      navigate(directory);
    }
  }, [directory, subDirectory, directoriesListService, directoriesListService.directoriesByGroups, navigate]);

  return (
    <CollapseSidebar
      width={{ min: 44, max: 284 }}
      open={isDirectoryListOpen}
      icon={
        <h4 className={styles.activeLibrary}>
          {currentDirectoryNameKey ? t(`labels:${currentDirectoryNameKey}.label`) : t('sidebar:collapsedTitle')}
        </h4>
      }
      title={<FilterType isOpen={isDirectoryListOpen} />}
      isLoading={directoriesListService.isLoading}
      className={styles.directoriesListSidebar}
      onToggle={setDirectoryListOpen}
    >
      <div className={styles.directoriesList}>
        <div className={styles.directoriesListControls}>
          <Input
            placeholder={t('collapse.search')}
            value={searchValue}
            onChange={handleSearchChange}
            className={styles.searchDirectoriesInput}
            prefix={
              <div className={styles.searchIcon}>
                <Search />
              </div>
            }
          />

          <div className={styles.collapseControls}>
            <Button variant="flat" onClick={() => controlAllCollapse(false)}>
              {t('collapse.hideAll')}
            </Button>

            <Button variant="flat" onClick={() => controlAllCollapse(true)}>
              {t('collapse.showAll')}
            </Button>
          </div>
        </div>

        {filteredDirectories.map((directory) =>
          directory.visible === false ? null : (
            <DirectoriesCollapse
              key={directory.title}
              isOpen={collapseControl[directory.title]}
              toggle={toggleCollapseOpen}
              directoriesGroup={directory}
              isDirectoryChangingBlocked={isDirectoryChangingBlocked}
            />
          ),
        )}
      </div>
    </CollapseSidebar>
  );
});
