import { isAxiosError as _isAxiosError, isCancel } from 'axios';

import type { AxiosError, AxiosResponse } from 'axios';

import { hasValue } from '../utils/has-value';

import { ApiError, OperationAbortedError } from './errors';
import { StatusCode } from './status-code';

export function isAxiosError(e: unknown): e is AxiosError<unknown> {
  return _isAxiosError(e);
}

export function throwApiError(e: unknown): never {
  if (e instanceof ApiError) {
    throw e;
  }

  if (isCancel(e)) {
    throw new OperationAbortedError(e.message ?? '', e);
  }

  if (!isAxiosError(e)) {
    if (e instanceof Error) {
      throw new ApiError(StatusCode.UNKNOWN, e.message ?? '', e);
    }

    throw new ApiError(StatusCode.UNKNOWN, 'error is neither AxiosError nor Error');
  }

  const errorMsg: string | null = tryGetErrorMessageFromResponse(e.response);

  if (errorMsg) {
    e.message = errorMsg;
  }

  const status: number = (function () {
    if (e.response && hasValue(e.response.status) && StatusCode[e.response.status]) {
      return e.response.status;
    }

    if (hasValue(e.status) && StatusCode[e.status]) {
      return e.status;
    }

    return StatusCode.UNKNOWN;
  })();

  throw new ApiError(status, errorMsg ?? '', e);
}

function tryGetErrorMessageFromResponse(response?: AxiosResponse): string | null {
  if (!response) {
    return null;
  }

  const messageRaw = response.data?.['message'];

  if (typeof messageRaw === 'string') {
    return messageRaw;
  }

  return null;
}
