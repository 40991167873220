import { requireService } from '@profgeosoft/di-ez';
import { DellDuotone, RefundBack } from '@profgeosoft-ui/icons';
import { Button, Switch } from '@profgeosoft-ui/react';

import { wrap } from 'src/packages/mobx-di/wrap';

import type { GridRenderCellParams } from '@profgeosoft-ui/react';
import type { MainDirectoryService } from 'src/services/directory-service';
import type { DirectoryRecord } from 'src/services/directory-service/entities/directory-record.entity';

import styles from './record-buttons.module.scss';

type Props = {
  params: GridRenderCellParams<DirectoryRecord>;
  directory: MainDirectoryService;
};

export const RecordButtons = wrap<Props>(function RecordButtons({ params, directory }) {
  const { isEditing } = requireService('directoryPage');
  const record = directory.getRecordById(params.row.recordId);

  if (!record) {
    console.warn('record is not presented');
    return null;
  }

  if (isEditing) {
    switch (record.recordChangeState) {
      case 'new': {
        return (
          <Button
            variant="flat"
            icon={<DellDuotone className={styles.deleteRecordIcon} />}
            onClick={() => directory.deleteRecord(record)}
          />
        );
      }

      case 'hasChanges': {
        return (
          <Button
            variant="flat"
            icon={<RefundBack className={styles.resetRecordIcon} />}
            onClick={() => directory.resetRecord(record)}
          />
        );
      }
    }
  }

  const { recordsActivity, updateDirectoryStatus } = directory;
  const isChecked = record.recordChangeState === 'new' || !!recordsActivity[record.recordId];

  return (
    <Switch
      checked={isChecked}
      onChange={(value) => updateDirectoryStatus(value, params.row.recordId)}
      disabled={isEditing || params.row.recordChangeState === 'new'}
    />
  );
});
