import { requireService } from '@profgeosoft/di-ez';
import { action, flow, makeObservable, observable } from 'mobx';

import { agent } from 'src/api/agent';
import { hasValue } from 'src/packages/utils/has-value';
import { createPromiseController, type TPromiseController } from 'src/packages/utils/promise-controller';
import { createControl } from 'src/services/directory-service/mappers/create-control';

import type { AxiosInstance } from 'axios';
import type { IControl, TActionButtonView, TControlView } from 'src/services/directory-service/types';
import type { INotificationsService } from 'src/services/notifications-service';

type TSerializedRoomsParams = {
  source: Record<string, unknown>;
  target: Record<string, unknown>;
};

export class CopyToAnotherRoomStore {
  private readonly notifications: INotificationsService;
  private readonly agent: AxiosInstance;
  private readonly directoryName: string;

  readonly view: TActionButtonView;
  readonly generalControlViews: TControlView[];
  readonly generalControls: IControl[];

  readonly sourceControls: IControl[];
  readonly targetControls: IControl[];

  @observable private confirmPromiseController: TPromiseController<boolean> | null = null;
  @observable isOpened = false;
  @observable isConfirmModalOpened = false;
  @observable isLoading = false;

  constructor(directoryName: string, view: TActionButtonView, generalControls: IControl[]) {
    this.notifications = requireService('notificationsService');
    this.view = view;
    this.directoryName = directoryName;
    this.generalControls = generalControls;
    this.agent = agent;

    this.sourceControls = view.copyModal.sourceRoom
      .map((controlView) => createControl(controlView, view.action.requiredParams))
      .reverse();

    this.targetControls = view.copyModal.targetRoom
      .map((controlView) => createControl(controlView, view.action.requiredParams))
      .reverse();

    makeObservable(this);
  }

  private serializeControlsValues(): TSerializedRoomsParams {
    const data: TSerializedRoomsParams = { source: {}, target: {} };

    this.sourceControls.forEach((control) => {
      if (hasValue(control.value)) {
        data.source[control.fieldId] = control.value;
      }
    });
    this.targetControls.forEach((control) => {
      if (hasValue(control.value)) {
        data.target[control.fieldId] = control.value;
      }
    });

    return data;
  }

  @action.bound
  setIsOpened(isOpened: boolean): void {
    this.isOpened = isOpened;
  }

  @action.bound
  setIsConfirmModalOpened(isOpened: boolean): void {
    this.isConfirmModalOpened = isOpened;
  }

  @action.bound
  prefillControls(): void {
    this.sourceControls.forEach((control) => {
      const correspondingGeneralControl = this.generalControls.find((ctrl) => ctrl.attrName === control.attrName);

      if (correspondingGeneralControl) {
        control.setValue(correspondingGeneralControl.value);
      }
    });
  }

  @action.bound
  resetControls(): void {
    this.sourceControls.forEach((control) => control.setValue(null));
    this.targetControls.forEach((control) => control.setValue(null));
  }

  @action.bound
  validate(): boolean {
    let isAllRequiredControlsFilledIn = true;

    const checkIsRequiredIsNotEmpty = (control: IControl): boolean => {
      const isValid = control.isRequired ? hasValue(control.value) : true;

      if (isValid) {
        return true;
      } else {
        control.setError('required field');

        return false;
      }
    };

    this.sourceControls.forEach((control) => {
      isAllRequiredControlsFilledIn = checkIsRequiredIsNotEmpty(control);
    });

    this.targetControls.forEach((control) => {
      isAllRequiredControlsFilledIn = checkIsRequiredIsNotEmpty(control);
    });

    if (!isAllRequiredControlsFilledIn) {
      this.notifications.showErrorMessageT('directory:components.copyToAnotherRoom.requiredValues');
      return false;
    }

    /** Параметры комнаты-донора и целевой комнаты не должны быть идентичными */
    let identicalPairs = 0;
    let isInvalidPairsPresented = false;

    for (let i = 0; i < this.sourceControls.length; i++) {
      if (this.sourceControls[i].value === this.targetControls[i].value) {
        identicalPairs += 1;
      }

      /** Контролы должны заполняться попарно. Т.е. если заполнен контрол, отвечающий за "Модель" комнаты-донора, то контрол "Модель" целевой комнаты тоже должен быть заполнен */
      if (hasValue(this.sourceControls[i].value) !== hasValue(this.targetControls[i].value)) {
        isInvalidPairsPresented = true;

        if (!hasValue(this.sourceControls[i].value)) {
          this.sourceControls[i].setError('filed should be filled in');
        } else {
          this.targetControls[i].setError('filed should be filled in');
        }
      }
    }

    if (identicalPairs === this.sourceControls.length) {
      this.notifications.showErrorMessageT('directory:components.copyToAnotherRoom.identicalValues');
      return false;
    }

    if (isInvalidPairsPresented) {
      this.notifications.showErrorMessageT('directory:components.copyToAnotherRoom.invalidPairs');
      return false;
    }

    return true;
  }

  @action.bound
  confirmCopying(): void {
    this.confirmPromiseController?.resolve(true);
    this.setIsConfirmModalOpened(false);
  }

  @action.bound
  denyCopying(): void {
    this.confirmPromiseController?.resolve(false);
    this.setIsConfirmModalOpened(false);
  }

  @flow.bound
  async *applyCopying() {
    if (!this.validate()) {
      return;
    }

    this.confirmPromiseController = createPromiseController();
    this.setIsConfirmModalOpened(true);

    const res = await this.confirmPromiseController;
    yield;

    if (!res) {
      return;
    }

    this.isLoading = true;

    const data = this.serializeControlsValues();

    try {
      await this.agent.post(`/view/action/copy/${this.directoryName}`, data);
      yield;

      this.setIsOpened(false);
      this.resetControls();
    } catch (e) {
      yield;

      console.error(e);
    } finally {
      this.isLoading = false;
    }
  }
}
