import { wrap } from 'src/packages/mobx-di/wrap';

import type { MainDirectoryService } from 'src/services/directory-service';
import type { IControl } from 'src/services/directory-service/types';

import { FormControl } from '../directory-form-sidebar/features/form/form-control';

type Props = {
  directory: MainDirectoryService;
  controls: IControl[];
};

export const GeneralControls = wrap<Props>(function GeneralControls({ directory, controls }) {
  return (
    <>
      {controls.map((control) => {
        const view = directory.controlsViewsMap.get(control.fieldId);

        if (!view) {
          return null;
        }

        return (
          <FormControl
            key={view.attrName}
            withLabel={false}
            control={control}
            controlView={view}
            optionsService={directory.optionsService}
            controls={controls}
            onChange={directory.onGeneralControlValueChange}
            onBlur={directory.onControlBlur}
          />
        );
      })}
    </>
  );
});
