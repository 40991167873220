import type { ObservableMap } from 'mobx';
import type { IDirectoriesStorage } from 'src/services/directories-storage-service/directories-storage-service';
import type { IControl, TCompareRule, TCompareRuleItem, TCompareRulesView } from 'src/services/directory-service/types';

import { handleValueSourceRule } from './handle-value-source-rule';

export type TMappedCompareRuleItem = Omit<TCompareRuleItem, 'compareWith' | 'toCompare'> & {
  compareWith?: unknown;
  toCompare: unknown;
};

export type TMappedCompareRule = TMappedCompareRuleItem | TMappedCompareRule[] | 'OR' | 'AND';

export const mapFilterRuleItems = (
  rules: TCompareRulesView,
  directories: IDirectoriesStorage,
  controlsMap: ObservableMap<string, IControl<unknown>>,
): TMappedCompareRule[] => {
  const mapRuleItem = (rule: TCompareRule): TMappedCompareRule => {
    if (rule === 'AND' || rule === 'OR') {
      return rule;
    }

    if (!Array.isArray(rule)) {
      const compareWith = (() => {
        if (rule.comparisonType === 'EMPTY' || rule.comparisonType === 'NON_EMPTY') {
          return;
        }

        if (rule.compareWith === null || typeof rule.compareWith !== 'object' || Array.isArray(rule.compareWith)) {
          return rule.compareWith;
        }

        return handleValueSourceRule(directories, controlsMap, rule.compareWith.sources)?.value;
      })();

      const toCompare = (() => {
        if (rule.toCompare === null || typeof rule.toCompare !== 'object' || Array.isArray(rule.toCompare)) {
          return rule.toCompare;
        }

        return handleValueSourceRule(directories, controlsMap, rule.toCompare.sources)?.value;
      })();

      return {
        comparisonType: rule.comparisonType,
        toCompare,
        compareWith,
      };
    } else {
      return rule.map(mapRuleItem);
    }
  };

  return rules.map(mapRuleItem);
};
