import { Scrollbar } from '@profgeosoft-ui/react';
import { useTranslation } from 'react-i18next';

import { wrap } from 'src/packages/mobx-di/wrap';

import type { AdditionalDirectoryService } from 'src/services/directory-service';
import type { DirectoryRecord } from 'src/services/directory-service/entities/directory-record.entity';

import { FormControl } from './form-control';

import styles from './sidebar-form.module.scss';

type Props = {
  directory: AdditionalDirectoryService;
  record: DirectoryRecord;
};

export const Form = wrap<Props>(function Form({ directory, record }) {
  const { t } = useTranslation();

  return (
    <Scrollbar>
      {directory.view.groups.map((group, index) => {
        return (
          <div key={index} className={styles.group}>
            {group.title && <p>{t(`labels:${group.title}.label`)}</p>}
            {group.columns.map((column, index) => {
              return (
                <div key={index} className={styles.column}>
                  {column.title && <p>{t(`labels:${column.title}.label`)}</p>}
                  <div className={styles.columnItemsWrapper}>
                    {column.rows.map((controlView) => {
                      const control = record.controlsMap.get(controlView.fieldId);

                      if (!control) {
                        console.warn('cannot find corresponding control for given view', controlView);

                        return null;
                      }

                      return (
                        <FormControl
                          key={controlView.attrName}
                          controlView={controlView}
                          control={control}
                          controls={record.controls}
                          optionsService={directory.optionsService}
                          onChange={directory.onControlValueChange}
                          onBlur={directory.onControlBlur}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </Scrollbar>
  );
});
