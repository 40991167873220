import { InvalidUserIdError, OperationAbortedError, throwApiError } from 'src/packages/errors';

import type { AxiosInstance } from 'axios';
import type { UserInfo } from '../auth-service/user-service';
import type { TUserSettings } from './user-settings-service';

interface IUserService {
  getUserData(): UserInfo | null;
}

export class UserSettingsServiceApi {
  private readonly agent: AxiosInstance;
  private readonly userService: IUserService;

  constructor(agent: AxiosInstance, userService: IUserService) {
    this.agent = agent;
    this.userService = userService;
  }

  private getUserId(): string {
    const sub = this.userService.getUserData()?.sub;

    if (!sub) {
      throw new InvalidUserIdError();
    }

    return sub;
  }

  async loadSettings(signal?: AbortSignal): Promise<{ id: number; data: TUserSettings }> {
    const sub = this.getUserId();

    try {
      const { data } = await this.agent.get('object/list/GoLibraryUserSettings', {
        params: {
          ownerUserId: sub,
        },
        signal,
      });

      return data.at(0) ?? null;
    } catch (e) {
      throwApiError(e);
    }
  }

  async updateSettings(id: number, newSettings: TUserSettings, signal?: AbortSignal): Promise<void> {
    try {
      await this.agent.patch(`object/GoLibraryUserSettings/${id}`, newSettings, { signal });
    } catch (e) {
      if (e instanceof OperationAbortedError) {
        return;
      }

      throwApiError(e);
    }
  }

  async createSettings(): Promise<{ id: number }> {
    const sub = this.getUserId();

    try {
      const { data } = await this.agent.post('object/new/GoLibraryUserSettings', { ownerUserId: sub, settings: {} });

      return data;
    } catch (e) {
      throwApiError(e);
    }
  }
}
