import { useLocation, useNavigate } from 'react-router-dom';

import { wrap } from 'src/packages/mobx-di/wrap';

import type { ReactNode } from 'react';

import { MenuItem } from '../menu-item/menu-item';

import { navProps } from './navigation.consts';

import styles from './navigation.module.scss';

export type TMenuItem = {
  name: string;
  to?: string;
  renderIcon: (className: string) => ReactNode;
  notifications?: {
    count: number;
  };
  onClick?: () => void;
};

type Props = {
  isOpened: boolean;
  onCloseSidebar(): void;
};

export const Navigation = wrap<Props>(function Navigation({ isOpened, onCloseSidebar }) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      {navProps.map((nav) => {
        const isActive = location.pathname === nav.to;

        const onClick = () => {
          onCloseSidebar();

          if (nav.isExternalLink) {
            window.open(nav.to, '_blank');
            return;
          }

          navigate(nav.to);
        };

        return <MenuItem key={nav.to} isActive={isActive} isOpened={isOpened} item={nav} onClick={onClick} />;
      })}
    </div>
  );
});
