import type { TControlView } from '../types/controls-views.type';
import type { IControl } from '../types/types';

import { CheckboxField } from '../entities/controls/checkbox-field';
import { ComboboxField } from '../entities/controls/combobox-field';
import { DateOnlyPickerField } from '../entities/controls/date-only-picker';
import { LabelField } from '../entities/controls/label-field';
import { MultiComboboxField } from '../entities/controls/multicombobox-field';
import { NumberField } from '../entities/controls/number-field';
import { StringField } from '../entities/controls/string-field';
import { StringsList } from '../entities/controls/strings-list';
import { YearOnlyPickerField } from '../entities/controls/year-only-picker';

function isNumberArray(value: unknown): value is number[] {
  return Array.isArray(value) && value.every((val) => typeof val === 'number');
}

function isStringArray(value: unknown): value is string[] {
  return Array.isArray(value) && value.every((val) => typeof val === 'string');
}

export const createControl = (
  controlView: TControlView,
  requiredFields: string[],
  initialValue?: unknown,
): IControl => {
  const { attrName, fieldId } = controlView;

  const attrKey: string | null = (() => {
    if (!attrName) {
      return null;
    }

    const [directoryName, dataFieldName, ...rest] = attrName.split('.');
    return rest.join('.');
  })();

  const control = (() => {
    switch (controlView.control) {
      case 'CheckBox': {
        const initValue = typeof initialValue === 'boolean' ? initialValue : undefined;

        return new CheckboxField(controlView, attrName || null, attrKey, fieldId, initValue);
      }

      case 'ComboBox': {
        const initValue = typeof initialValue === 'number' ? initialValue : undefined;

        return new ComboboxField(controlView, attrName || null, attrKey, fieldId, initValue);
      }

      case 'DateOnlyPicker': {
        const initValue = typeof initialValue === 'number' ? initialValue : undefined;

        return new DateOnlyPickerField(controlView, attrName || null, attrKey, fieldId, initValue);
      }

      case 'Field': {
        if (controlView.type === 'String') {
          const initValue = typeof initialValue === 'string' ? initialValue : undefined;

          return new StringField(controlView, attrName || null, attrKey, fieldId, initValue);
        } else {
          const initValue = typeof initialValue === 'number' ? initialValue : undefined;

          return new NumberField(controlView, attrName || null, attrKey, fieldId, initValue);
        }
      }

      case 'Label': {
        const isSimple = !('refObjectType' in controlView);

        return new LabelField(isSimple, controlView, attrName || null, attrKey, fieldId, initialValue);
      }

      case 'MultiComboBox': {
        const initValue = isNumberArray(initialValue) ? initialValue : undefined;

        return new MultiComboboxField(controlView, attrName || null, attrKey, fieldId, initValue);
      }

      case 'StringsList': {
        const initValue = isStringArray(initialValue) ? initialValue : undefined;

        return new StringsList(controlView, attrName || null, attrKey, fieldId, initValue);
      }

      case 'YearOnlyPicker': {
        const initValue = typeof initialValue === 'number' ? initialValue : undefined;

        return new YearOnlyPickerField(controlView, attrName || null, attrKey, fieldId, initValue);
      }
    }
  })();

  if (requiredFields.includes(control.fieldId)) {
    control.setIsRequired(true);
  }

  return control;
};
