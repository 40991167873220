import { hasValue } from 'src/packages/utils/has-value';

import type { TMappedCompareRuleItem, TMappedCompareRule } from './map-filter-rule-items';

const compare = (value: unknown, filterItem: TMappedCompareRuleItem): boolean => {
  switch (filterItem.comparisonType) {
    case 'EQUALS': {
      return value === filterItem.compareWith;
    }

    case 'NOT_EQUALS': {
      return value !== filterItem.compareWith;
    }

    case 'CONTAINS': {
      if (!Array.isArray(value) && typeof value !== 'string') {
        return false;
      }

      if (Array.isArray(value)) {
        return value.includes(filterItem.compareWith);
      }

      if (typeof value === 'string' && typeof filterItem.compareWith === 'string') {
        return value.includes(filterItem.compareWith);
      }

      return false;
    }

    case 'NOT_CONTAINS': {
      if (!Array.isArray(value) && typeof value !== 'string') {
        return false;
      }

      if (Array.isArray(value)) {
        return !value.includes(filterItem.compareWith);
      }

      if (typeof value === 'string' && typeof filterItem.compareWith === 'string') {
        return !value.includes(filterItem.compareWith);
      }

      return false;
    }

    case 'EMPTY': {
      if (Array.isArray(value)) {
        return !value.length;
      }

      if (typeof value === 'string') {
        return value.trim() === '';
      }

      return !hasValue(value);
    }

    case 'NON_EMPTY': {
      if (Array.isArray(value)) {
        return !!value.length;
      }

      if (typeof value === 'string') {
        return value.trim() !== '';
      }

      return hasValue(value);
    }

    default: {
      return false;
    }
  }
};

const compareDataWithFilterItem = (data: Record<string, unknown>, filterItem: TMappedCompareRuleItem): boolean => {
  if (typeof filterItem.toCompare === 'string' && filterItem.toCompare.startsWith('{')) {
    const key = filterItem.toCompare.slice(1, filterItem.toCompare.length - 1);

    return compare(data[key], filterItem);
  } else {
    return compare(filterItem.toCompare, filterItem);
  }
};

export const compareDataWithFilterItems = (data: Record<string, unknown>, filters: TMappedCompareRule[]): boolean => {
  if (!filters.length) {
    return true;
  }

  if (filters.length === 1) {
    if (Array.isArray(filters[0])) {
      console.error('filter item cannot be an array. Remove unnecessary nesting', filters[0], filters);
      return false;
    }
    // В этом случае filters[0] всегда будет TMappedCompareRuleItem. Если это не так, то где-то ошибка в описании структуры условий
    return compareDataWithFilterItem(data, filters[0] as TMappedCompareRuleItem);
  }

  if (filters.length % 2 === 0) {
    console.error('Error in filter structure', filters);
    return false;
  }

  for (let i = 0; i < filters.length; i += 2) {
    const filterItem = filters[i];
    const operator = filters[i + 1];

    if (!['OR', 'AND'].includes(operator) && i !== filters.length - 1) {
      console.error('operator should be "OR" or "AND" strings', operator);
      return false;
    }

    if (typeof filterItem !== 'object') {
      console.error('filterItem should be an onject', filterItem);
      return false;
    }

    const result = Array.isArray(filterItem)
      ? compareDataWithFilterItems(data, filterItem)
      : compareDataWithFilterItem(data, filterItem);

    if (operator === 'OR' && result) {
      return true;
    }

    if (operator === 'AND' && !result) {
      return false;
    }

    if (i === filters.length - 1) {
      return result;
    }
  }

  return false;
};
