import { requireService } from '@profgeosoft/di-ez';
import { RadioButtonGroup } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { wrap } from 'src/packages/mobx-di/wrap';

import styles from './filter-type.module.scss';

type Props = {
  isOpen: boolean;
};

const FilterType = wrap<Props>(function FilterType({ isOpen }) {
  const directoriesListService = requireService('directoriesListService');

  const { t } = useTranslation('directoriesList');

  if (!isOpen) return null;

  return (
    <RadioButtonGroup
      className={styles.buttonGroup}
      onChange={directoriesListService.setFilterType}
      items={[
        {
          value: 'all',
          label: t('directoriesType.all'),
          className: clsx(styles.leftButton, directoriesListService.filterType === 'all' && styles.active),
        },
        {
          value: 'favorites',
          label: t('directoriesType.favorites'),
          className: clsx(styles.rightButton, directoriesListService.filterType !== 'all' && styles.active),
        },
      ]}
      value={'all'}
    />
  );
});

export default FilterType;
