import { throwApiError } from 'src/packages/errors';

import type { AxiosInstance } from 'axios';
import type { TDirectoryGroup } from './directories-list-service';

export class DirectoriesListApi {
  private readonly agent: AxiosInstance;

  constructor(agent: AxiosInstance) {
    this.agent = agent;
  }

  async getAllDirectories(): Promise<TDirectoryGroup[]> {
    try {
      const { data } = await this.agent.get<{ groups: TDirectoryGroup[] }>('/settings/object_types');

      return data.groups;
    } catch (e) {
      throwApiError(e);
    }
  }
}
