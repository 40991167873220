import { type ObservableMap } from 'mobx';

import { hasValue } from 'src/packages/utils/has-value';

import type { TFilterOptionsRule } from '../mappers';
import type { IDirectoriesStorage } from 'src/services/directories-storage-service/directories-storage-service';
import type { ComboboxField } from 'src/services/directory-service/entities/controls/combobox-field';
import type { MultiComboboxField } from 'src/services/directory-service/entities/controls/multicombobox-field';
import type { IControl } from 'src/services/directory-service/types';

import { compareDataWithFilterItems } from './compare-data-with-filter-items';
import { mapFilterRuleItems } from './map-filter-rule-items';

export const handleFilterOptionsRule = (
  directoryService: IDirectoriesStorage,
  controlsMap: ObservableMap<string, IControl<unknown>>,
  depControl: ComboboxField | MultiComboboxField,
  rule: TFilterOptionsRule,
  value: unknown,
): { allowedOptions: Record<number, boolean> | null } => {
  if (!hasValue(value)) {
    return {
      allowedOptions: null,
    };
  }

  const allowedOptions: Record<number, boolean> = {};

  const directoryRecords = directoryService.directories[depControl.view.refObjectType];
  const filters = mapFilterRuleItems(rule.ruleView.filterRules, directoryService, controlsMap);

  for (const directoryRecord of directoryRecords) {
    if (compareDataWithFilterItems(directoryRecord.data, filters)) {
      allowedOptions[directoryRecord.id] = true;
    }
  }

  return {
    allowedOptions,
  };
};
