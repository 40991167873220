import { Checkbox } from '@profgeosoft-ui/react';

import { wrap } from 'src/packages/mobx-di/wrap';

import type { CheckboxField } from 'src/services/directory-service/entities/controls/checkbox-field';
import type { IControl } from 'src/services/directory-service/types';

type Props = {
  control: CheckboxField;
  isDisabled?: boolean;
  onChange(control: IControl, value: unknown): void;
};

export const CheckboxComponent = wrap<Props>(function CheckboxComponent({ control, isDisabled, onChange }) {
  const handleChange = (value: boolean) => {
    onChange(control, value);
  };

  return (
    <Checkbox
      checked={control.value}
      disabled={isDisabled || control.isDisabled || control.isEnteringBlocked}
      onChange={handleChange}
    />
  );
});
