import type { TControlView, TValueSourceObject } from './types';

export function getObjectTypesFromControlView(controlView: TControlView): string[] {
  const objectTypes: string[] = [];

  if ('refObjectType' in controlView) {
    objectTypes.push(controlView.refObjectType);
  }

  if ('valueSource' in controlView && controlView.valueSource) {
    const processNestedSource = (sourceObj: TValueSourceObject): void => {
      if (sourceObj.sourceType === 'directory') {
        objectTypes.push(sourceObj.sourceDirectory);
      }

      if (sourceObj.isSourceFor) {
        return processNestedSource(sourceObj.isSourceFor);
      }
    };

    if (!Array.isArray(controlView.valueSource.sources)) {
      processNestedSource(controlView.valueSource.sources);
    } else {
      controlView.valueSource.sources.forEach((sourceObj) => processNestedSource(sourceObj));
    }
  }

  return objectTypes;
}
