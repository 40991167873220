import i18next from 'i18next';

import { PRIMARY_LANG, SECONDARY_LANG } from 'src/services/localization-service.ts/localization.consts';

export enum LANGUAGES {
  ru = 'ru',
  en = 'en',
  es = 'es',
}

export function isLanguage(value: unknown): value is LANGUAGES {
  return !!value && typeof value === 'string' && Object.values(LANGUAGES).includes(value);
}

export function getLocale(): string {
  return isLanguage(i18next.language) ? i18next.language : PRIMARY_LANG ?? SECONDARY_LANG ?? LANGUAGES.ru;
}
