import { throwApiError } from 'src/packages/errors';

import type { AxiosInstance } from 'axios';

export class ViewsApi<TViewTypes> {
  private readonly agent: AxiosInstance;

  constructor(agent: AxiosInstance) {
    this.agent = agent;
  }

  async getView<TView>(viewName: string, type: TViewTypes, signal?: AbortSignal): Promise<TView> {
    try {
      const { data } = await this.agent.get<TView>(`view/${viewName}/${type}`, { signal });

      return data;
    } catch (e) {
      throwApiError(e);
    }
  }
}
