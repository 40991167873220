import { BackLight, CloseRoundLight } from '@profgeosoft-ui/icons';
import { Button, Illustration, Modal } from '@profgeosoft-ui/react';

import { wrap } from 'src/packages/mobx-di/wrap';

import styles from './discard-changes-modal.module.scss';

type Props = {
  isOpen: boolean;
  modalTitle: string;
  modalText: string;
  okText: string;
  cancelText: string;
  onCancel(): void;
  onOk(): void;
};

export const DiscardChangesModal = wrap<Props>(function DiscardChangesModal({
  isOpen,
  modalTitle,
  modalText,
  okText,
  cancelText,
  onCancel,
  onOk,
}) {
  return (
    <Modal open={isOpen} closeIcon={null} className={styles.modalWrapper}>
      <div className={styles.modalInfo}>
        <Illustration.CloseNotSave />
        <div className={styles.modalText}>
          <p className={styles.title}>{modalTitle}</p>
          <p className={styles.text}>{modalText}</p>
        </div>
      </div>
      <div className={styles.modalControl}>
        <Button
          variant="danger"
          className={styles.button}
          onClick={onCancel}
          icon={<CloseRoundLight />}
          title={cancelText}
        >
          {cancelText}
        </Button>
        <Button variant="primary" className={styles.button} onClick={onOk} icon={<BackLight />} title={okText}>
          {okText}
        </Button>
      </div>
    </Modal>
  );
});
