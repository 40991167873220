import { Component } from 'react';

import type { PropsWithChildren, ReactNode, ReactElement } from 'react';

type Props = {
  errorStub: ReactElement;
};

// eslint-disable-next-line mobx-di/no-missing-wrapper
export class ErrorBoundary extends Component<PropsWithChildren<Props>, { hasError: boolean }> {
  constructor(props: PropsWithChildren<Props>) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: unknown) {
    console.error(error);
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return this.props.errorStub;
    }

    return this.props.children;
  }
}
