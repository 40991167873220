import { ExpandDownLight, StarFill, StarLight } from '@profgeosoft-ui/icons';
import { Button, Collapse } from '@profgeosoft-ui/react';
import { requireService } from '@profgeosoft/di-ez';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { wrap } from 'src/packages/mobx-di/wrap';

import type { TDirectoryGroup } from 'src/services/directories-list-service/directories-list-service';

import styles from './directories-collapse.module.scss';

type Props = {
  directoriesGroup: TDirectoryGroup;
  isOpen: boolean | undefined;
  isDirectoryChangingBlocked: boolean;
  toggle(id: string): void;
};

const DirectoriesCollapse = wrap<Props>(function DirectoriesCollapse({
  directoriesGroup,
  isOpen,
  isDirectoryChangingBlocked,
  toggle,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const directoriesListService = requireService('directoriesListService');
  const { currentDirectory } = directoriesListService;
  const isActiveGroup = !!directoriesGroup.items.find((dir) => dir.objectType === currentDirectory);
  const onNavigate = (to: string) => {
    navigate(to);
    directoriesListService.setCurrentDirectory(to);
  };

  return (
    <Collapse.Panel
      panelKey={directoriesGroup.title}
      header={
        <div className={clsx(styles.collapseHeader, isOpen && styles.collapseHeader_open)}>
          <p className={clsx(styles.collapseHeader__text, isActiveGroup && styles.collapseHeader__text_active)}>
            {t(`labels:${directoriesGroup.title}.label`)}
          </p>
          <ExpandDownLight
            className={clsx(styles.icon, isOpen && styles.icon_open, isActiveGroup && styles.icon_active)}
          />
        </div>
      }
      className={styles.wrapper}
      isActive={isOpen}
      onItemClick={() => toggle(directoriesGroup.title)}
    >
      {directoriesGroup.items.map((item) => {
        if (item.visible === false) {
          return null;
        }

        const isActive = item.objectType === currentDirectory;
        const isFavorite = directoriesListService.favoritesObjectTypes.includes(item.objectType);

        return (
          <div
            key={item.objectType}
            className={clsx(
              styles.collapseItem,
              isActive && styles.collapseItem_active,
              isDirectoryChangingBlocked && styles.collapseItem_disabled,
            )}
          >
            <button
              className={styles.linkButton}
              disabled={isDirectoryChangingBlocked}
              onClick={() => onNavigate(item.objectType)}
            >
              <p className={clsx(styles.linkText, isActive && styles.linkText_active)}>
                {t(`labels:${item.title}.label`)}
              </p>
            </button>
            <Button
              className={styles.favoriteButton}
              variant="flat"
              icon={
                isFavorite ? (
                  <StarFill className={clsx(styles.favoriteIcon, styles.fillIcon)} />
                ) : (
                  <StarLight className={styles.favoriteIcon} />
                )
              }
              onClick={() => directoriesListService.updateFavoriteDirectories(item.objectType, !isFavorite)}
            />
          </div>
        );
      })}
    </Collapse.Panel>
  );
});

export default DirectoriesCollapse;
