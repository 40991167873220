import { Select } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { reaction } from 'mobx';
import { useState, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ComboboxDropdown } from 'src/components/combobox-dropdown';
import { wrap } from 'src/packages/mobx-di/wrap';

import type { RefSelectProps } from '@profgeosoft-ui/react';
import type { MutableRefObject } from 'react';
import type { TOption } from 'src/packages/types';
import type { MultiComboboxField } from 'src/services/directory-service/entities/controls/multicombobox-field';
import type { OptionsService } from 'src/services/directory-service/options-service';
import type { IControl, TMultiComboBoxView } from 'src/services/directory-service/types';

import styles from './multicombobox.module.scss';

type Props = {
  control: MultiComboboxField;
  controlView: TMultiComboBoxView;
  optionsService: OptionsService;
  className?: string;
  innerRef?: MutableRefObject<RefSelectProps | null>;
  onChange(control: IControl, value: unknown): void;
  loadOptionsFn: (signal?: AbortSignal) => Promise<TOption[]>;
  onBlur?(control: IControl): void;
  onCreateNewRecord: () => void;
};

export const MulticomboboxComponent = wrap(function MulticomboboxComponent({
  innerRef,
  control,
  controlView,
  optionsService,
  className,
  loadOptionsFn,
  onCreateNewRecord,
  onChange,
  onBlur,
}: Props) {
  const ref = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [internalOptions, setInternalOptions] = useState<TOption[]>([]);

  const value = (() => {
    const valueObjects: TOption[] = [];

    for (const value of control.value) {
      const correspondingOption =
        internalOptions.find((opt) => opt.value === value) ??
        optionsService.archivedOptions[control.fieldId]?.find((opt) => opt.value === value);

      if (!correspondingOption) {
        continue;
      }

      valueObjects.push(correspondingOption);
    }

    return valueObjects;
  })();

  const loadOptions = useCallback((): VoidFunction => {
    const abortController = new AbortController();

    const load = async () => {
      control.setIsLoading(true);
      const options = await loadOptionsFn(abortController.signal);
      setInternalOptions(options);
      control.setIsLoading(false);
    };

    load();

    return () => abortController.abort();
  }, [control, loadOptionsFn]);

  const handleChange = (rawValue: (number | TOption)[]) => {
    const processedValues: number[] = [];

    for (const value of rawValue) {
      if (typeof value === 'number') {
        processedValues.push(value);
        continue;
      }

      processedValues.push(value.value);
    }

    onChange(control, processedValues);

    setTimeout(() => ref.current?.scrollIntoView(), 0);
  };

  const handleBlur = () => {
    onBlur?.(control);
  };

  useEffect(() => {
    if (!controlView.refQuery) {
      return;
    }

    return loadOptions();
  }, [controlView.refQuery, loadOptions]);

  useEffect(() => {
    return reaction(
      () => optionsService.options[control.fieldId] ?? [],
      (options) => setInternalOptions(options),
      { fireImmediately: true },
    );
  }, [control.fieldId, optionsService.options]);

  const isOpened = isOpen && !control.isLoading && !control.isDisabled && !control.isEnteringBlocked;

  return (
    <div ref={ref}>
      <Select<TOption[] | number[]>
        ref={innerRef}
        open={isOpened}
        value={control.isLoading ? [] : value}
        options={internalOptions}
        dropdownMatchSelectWidth={false}
        className={clsx(styles.multicombobox, className)}
        allowClear
        loading={control.isLoading}
        disabled={control.isDisabled || control.isEnteringBlocked}
        placeholder={t('common:placeholders.chooseValue')}
        mode="multiple"
        status={control.error ? 'error' : undefined}
        onDropdownVisibleChange={(open) => setIsOpen(open)}
        dropdownRender={(menu) => (
          <ComboboxDropdown onButtonClick={onCreateNewRecord} onClose={() => setIsOpen(false)}>
            {menu}
          </ComboboxDropdown>
        )}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </div>
  );
});
