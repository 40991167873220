import { computed, makeObservable, observable } from 'mobx';

import type { IControl } from '../types/types';

export class DirectoryRecord {
  readonly recordId: number;

  @observable readonly controls: IControl[] = [];
  readonly controlsMap = observable.map<string, IControl>();

  constructor(controls: IControl[], id: number) {
    this.recordId = id;
    this.controls = [...controls];
    controls.forEach((control) => {
      this.controlsMap.set(control.fieldId, control);
    });

    makeObservable(this);
  }

  @computed
  get recordChangeState(): 'new' | 'hasChanges' | null {
    if (this.recordId < 0) {
      return 'new';
    }

    if (this.controls.some((control) => control.hasChanges)) {
      return 'hasChanges';
    }

    return null;
  }
}
