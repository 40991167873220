export const localStorageUtil = {
  get<TValue = null | unknown>(key: string): null | TValue {
    try {
      const value = localStorage.getItem(key);

      if (!value) return null;

      return JSON.parse(value);
    } catch (e) {
      console.error(e);
      return null;
    }
  },

  set(key: string, value: unknown) {
    try {
      const jsonValue = JSON.stringify(value);
      localStorage.setItem(key, jsonValue);
    } catch (e) {
      console.error(e);
    }
  },

  delete(key: string) {
    localStorage.removeItem(key);
  },

  getKeys() {
    Object.keys(localStorage);
  },
};
