import { useTranslation } from 'react-i18next';

import { wrap } from 'src/packages/mobx-di/wrap';

import styles from './table-header.module.scss';

type Props = {
  textTKey: string;
  field?: string;
};

export const TableHeader = wrap<Props>(function TableHeader({ textTKey, field }) {
  const { t } = useTranslation();
  // Temporary disabled
  // const unitsService = requireService('unitsService');
  // const unit = field && unitsService.getUnitByFieldName(field);
  // const unit = field && t(`labels:${field}`);

  // const unitTKey = unit ? `units:${unit}` : null;
  const unitTKey = null;

  return (
    <div className={styles.wrapper} title={`${t(textTKey)}${unitTKey ? `, ${t(unitTKey)}` : ''}`}>
      <h4 className={styles.header}>{`${t(textTKey)}${unitTKey ? ', ' : ''}`}</h4>
      {unitTKey && <p className={styles.unit}>{t(unitTKey)}</p>}
    </div>
  );
});
