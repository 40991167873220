import { CopyAltLight } from '@profgeosoft-ui/icons';
import { Button } from '@profgeosoft-ui/react';
import { requireService } from '@profgeosoft/di-ez';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { wrap } from 'src/packages/mobx-di/wrap';

import type { MainDirectoryService } from 'src/services/directory-service';
import type { TActionButtonView } from 'src/services/directory-service/types';

import { CopyToAnotherRoomModal } from './copy-to-another-room-modal';
import { CopyToAnotherRoomStore } from './copy-to-another-room.store';

import styles from './copy-to-another-room-modal.module.scss';

type Props = {
  view: TActionButtonView;
  directory: MainDirectoryService;
};

export const CopyToAnotherRoom = wrap<Props>(function CopyToAnotherRoom({ view, directory }) {
  const { t, i18n } = useTranslation();
  const { isEditing } = requireService('directoryPage');
  const buttonName = i18n.exists(`labels:${view.fieldId}.label`)
    ? t(`labels:${view.fieldId}.label`)
    : t('directory:components.copyToAnotherRoom.copyData');

  const [store] = useState(() => new CopyToAnotherRoomStore(directory.directoryName, view, directory.generalControls));

  const handleOpen = () => {
    store.setIsOpened(true);
    store.prefillControls();
  };

  if (isEditing) {
    return null;
  }

  return (
    <>
      <Button icon={<CopyAltLight />} onClick={handleOpen}>
        <p className={styles.buttonText}>{buttonName}</p>
      </Button>
      <CopyToAnotherRoomModal store={store} optionsService={directory.optionsService} />
    </>
  );
});
