import type { Cancel } from 'axios';

import { StatusCode } from './status-code';

export class ApiError extends Error {
  constructor(
    public statusCode: StatusCode = StatusCode.UNKNOWN,
    public message: string,
    public error?: Error,
  ) {
    super();
  }
}

export class AssertionError extends Error {}

/**
 * Операция отменена вызывающей стороной
 */
export class OperationAbortedError extends Error {
  constructor(
    public reason: string,
    public error?: Cancel,
  ) {
    super();
  }
}

/**
 * Операция прекратилась по внутренней причине
 */
export class OperationCancelledError extends Error {}

export class OperationFailedError extends Error {}

export class PlatformRejectsOperationError extends Error {}

export class InvalidUserIdError extends Error {}

export function isApiErrorWithViolations<TViolation>(e: unknown): e is { violations: TViolation[] } {
  return !!e && typeof e === 'object' && 'violations' in e;
}

export type TUpdateObjectListViolation = { index: number; attrName: string; message?: string };

export class ApiErrorWithViolations<TViolation> extends ApiError {
  constructor(
    public statusCode: StatusCode = StatusCode.UNKNOWN,
    public message: string,
    public violations: TViolation[],
    public error?: Error,
  ) {
    super(statusCode, message, error);
  }
}

export class UpdateObjectListError extends ApiErrorWithViolations<TUpdateObjectListViolation> {}
