import { OperationAbortedError, throwApiError } from 'src/packages/errors';
import { throttle } from 'src/packages/utils/throttle';

import type { TTableSettings } from './table-settings-service';
import type { AxiosInstance } from 'axios';

export class TableSettingsServiceApi {
  private readonly agent: AxiosInstance;
  private updateTableSettingsAbortController: AbortController | null = null;

  constructor(agent: AxiosInstance) {
    this.agent = agent;
  }

  async loadSettings(userId: string, signal?: AbortSignal): Promise<{ id: number; data: TTableSettings }> {
    try {
      const { data } = await this.agent.get('object/list/GoLibraryTableSettings', {
        params: {
          ownerUserId: userId,
        },
        signal,
      });

      return data.at(0) ?? null;
    } catch (e) {
      throwApiError(e);
    }
  }

  async updateSettings(id: number, newSettings: TTableSettings, signal?: AbortSignal): Promise<void> {
    if (this.updateTableSettingsAbortController) {
      this.updateTableSettingsAbortController.abort();
    }

    const abortController = new AbortController();
    this.updateTableSettingsAbortController = abortController;

    try {
      await this.agent.patch(`object/GoLibraryTableSettings/${id}`, newSettings, { signal: abortController.signal });
    } catch (e) {
      if (e instanceof OperationAbortedError) {
        return;
      }

      throwApiError(e);
    } finally {
      this.updateTableSettingsAbortController = null;
    }
  }

  throttleUpdateSettings = throttle(
    (id: number, newSettings: TTableSettings, signal?: AbortSignal) => this.updateSettings(id, newSettings, signal),
    500,
  );

  async createSettings(settings: TTableSettings): Promise<{ id: number }> {
    try {
      const { data } = await this.agent.post('object/new/GoLibraryTableSettings', settings);

      return data;
    } catch (e) {
      throwApiError(e);
    }
  }
}
