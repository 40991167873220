import { action, computed, makeObservable, observable } from 'mobx';
import { nanoid } from 'nanoid';

import { hasValue } from 'src/packages/utils/has-value';

import type { IControl, TMultiComboBoxView } from '../../types';

export class MultiComboboxField implements IControl<number[]> {
  readonly id = nanoid(16);
  readonly view: TMultiComboBoxView;
  readonly fieldId: string;
  readonly attrName: string | null;
  readonly attrKey: string | null;
  readonly initialValue: number[] = [];

  @observable value: number[] = [];
  @observable isEnteringBlocked = false;
  @observable isDisabled = false;
  @observable error: string | null = null;
  @observable isLoading = false;
  @observable isRequired = false;
  @observable allowedOptions: Record<number, boolean> | null = null;

  constructor(
    view: TMultiComboBoxView,
    attrName: string | null,
    attrKey: string | null,
    fieldId: string,
    initialValue?: number[],
  ) {
    this.initialValue = initialValue ?? [];
    this.view = view;
    this.fieldId = fieldId;
    this.attrName = attrName;
    this.attrKey = attrKey;

    if (hasValue(initialValue) && initialValue.length > 0) {
      this.value = initialValue;
    }

    makeObservable(this);
  }

  @computed
  get hasChanges(): boolean {
    if (!this.attrName) {
      return false;
    }

    if (!this.initialValue.length && !this.value.length) {
      return false;
    }

    if (this.initialValue.length !== this.value?.length) {
      return true;
    }

    const sortedInitialValue = [...this.initialValue].sort();
    const sortedValue = [...this.value].sort();

    for (let i = 0; i < sortedValue.length; i++) {
      if (sortedValue[i] !== sortedInitialValue[i]) {
        return true;
      }
    }

    return false;
  }

  @action.bound
  setValue(value: number[] | null): void {
    if (!value) {
      this.value = [];
      return;
    }

    this.value = value;
  }

  @action.bound
  setIsRequired(isRequired: boolean): void {
    this.isRequired = isRequired;
  }

  @action.bound
  setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  @action.bound
  setError(error: string | null): void {
    this.error = error;
  }

  @action.bound
  setIsDisabled(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  @action.bound
  setIsEnteringBlocked(isEnteringBlocked: boolean): void {
    this.isEnteringBlocked = isEnteringBlocked;
  }

  @action.bound
  setAllowedOptions(optionsRecord: Record<number, boolean> | null): void {
    this.allowedOptions = optionsRecord;
  }
}
