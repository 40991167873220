import { computed, flow, makeObservable, observable } from 'mobx';

import { ApiError, OperationAbortedError } from 'src/packages/errors';
import { hasValue } from 'src/packages/utils/has-value';

import type { UserInfo } from '../auth-service/user-service';
import type { INotificationsService } from '../notifications-service';
import type { GridFilterModel } from '@profgeosoft-ui/react';
import type { AxiosInstance } from 'axios';

import { TableSettingsServiceApi } from './table-settings-service.api';

export type TTableSortItem = {
  field: string;
  sort: 'asc' | 'desc';
};

export type TTablePinModel = {
  left?: string[];
  right?: string[];
};

export type TFilterItem = {
  field: string;
  operator: string;
  id: string;
  value?: string | number;
};

export type TTableSettingsObject = {
  columnsVisibility?: Record<string, boolean>;
  pinnedColumns?: TTablePinModel;
  sortingModel?: TTableSortItem[];
  columnsOrderModel?: string[];
  columnsWidth?: Record<string, number>;
  filterModel?: GridFilterModel;
  fieldValues?: Record<string, unknown>;
};

export type TTableSettings = {
  ownerUserId: string;
  settings: Record<string, TTableSettingsObject>;
};

interface IUserService {
  getUserData(): UserInfo | null;
}

export class TableSettingsService {
  private readonly notifications: INotificationsService;
  private readonly userService: IUserService;
  private readonly api: TableSettingsServiceApi;

  @observable private settingsId: number | null = null;
  @observable.deep private settings: TTableSettings | null = null;

  @observable isSettingsLoading = false;

  constructor(agent: AxiosInstance, userService: IUserService, notificationsService: INotificationsService) {
    this.userService = userService;
    this.notifications = notificationsService;
    this.api = new TableSettingsServiceApi(agent);
    makeObservable(this);
  }

  private async createSettings(): Promise<{ id: number; data: TTableSettings } | null> {
    const userData = this.userService.getUserData();

    if (!userData?.sub) {
      return null;
    }

    await this.api.createSettings({ ownerUserId: userData.sub, settings: {} });

    return this.api.loadSettings(userData.sub);
  }

  private async getSettings(): Promise<{ id: number; data: TTableSettings } | null> {
    const userData = this.userService.getUserData();

    if (!userData?.sub) {
      return null;
    }

    try {
      const settings = await this.api.loadSettings(userData.sub);

      if (settings) {
        return settings;
      } else {
        return await this.createSettings();
      }
    } catch (e) {
      if (e instanceof ApiError && e.statusCode === 404) {
        return await this.createSettings();
      }

      throw e;
    }
  }

  @computed
  get tableSettings(): Record<string, TTableSettingsObject> | null {
    if (!this.settings) {
      return null;
    }

    return this.settings.settings;
  }

  @flow.bound
  async *getOrCreateSettings() {
    if (this.settings) {
      return this.settings.settings;
    }

    this.isSettingsLoading = true;

    try {
      const settings = await this.getSettings();
      yield;

      if (settings) {
        this.settingsId = settings.id;
        this.settings = settings.data;
      }

      return this.settings?.settings;
    } catch (e) {
      yield;

      if (e instanceof OperationAbortedError) {
        return null;
      }

      if (e instanceof ApiError) {
        if (e.message) {
          this.notifications.showErrorMessage(e.message);
        }
      }

      this.notifications.showErrorMessageT('settings:table.errors.failedToLoadSettings');
    } finally {
      yield;
      this.isSettingsLoading = false;
    }

    return null;
  }

  @flow.bound
  async *updateSettings(directoryName: string, settings: TTableSettingsObject) {
    const tableSettings = await this.getOrCreateSettings();
    yield;

    if (!hasValue(this.settingsId) || !this.settings) {
      return null;
    }

    const newSettings = {
      ...this.settings,
      settings: {
        ...tableSettings,
        [directoryName]: settings,
      },
    };

    yield;

    this.settings = newSettings;

    try {
      this.api.throttleUpdateSettings(this.settingsId, this.settings);

      return this.getOrCreateSettings();
    } catch (e) {
      yield;

      if (e instanceof OperationAbortedError) {
        return null;
      }

      if (e instanceof ApiError && e.message) {
        this.notifications.showErrorMessage(e.message);
        return null;
      }

      this.notifications.showErrorMessageT('settings:table.errors.failedToUpdateTableSettings');
    }

    return null;
  }

  init = () => {
    this.getOrCreateSettings();
  };
}
