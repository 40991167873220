import type { TRequiredIfRule } from '../mappers';
import type { ObservableMap } from 'mobx';
import type { IDirectoriesStorage } from 'src/services/directories-storage-service/directories-storage-service';
import type { IControl } from 'src/services/directory-service/types';

import { compareDataWithFilterItems } from './compare-data-with-filter-items';
import { mapFilterRuleItems } from './map-filter-rule-items';

export const handleRequiredIfRule = (
  directoryService: IDirectoriesStorage,
  controlsMap: ObservableMap<string, IControl<unknown>>,
  depControl: IControl,
  rule: TRequiredIfRule,
): { isRequired: boolean } => {
  const filters = mapFilterRuleItems(rule.ruleView.requirednessRules, directoryService, controlsMap);

  return { isRequired: compareDataWithFilterItems({}, filters) };
};
