import type {
  TCalculatedValueView,
  TCompareRuleItem,
  TCompareRulesView,
  TConcatinationView,
  TDirectorySource,
  TEnableIfView,
  TFilterOptionsView,
  TRequiredIfView,
  TRestrictions,
  TValueSourceView,
} from 'src/services/directory-service/types/control-rules-views.type';
import type { TControlView } from 'src/services/directory-service/types/controls-views.type';

export type TRule =
  | TEnableIfRule
  | TValueSourceRule
  | TRestrictionsRule
  | TFilterOptionsRule
  | TConcatinationRule
  | TCalculatedValueRule
  | TRequiredIfRule;

export type TEnrichedRule<T = TRule> = {
  managmentControls: string[];
  rule: T;
};

export function mapControlRules(control: TControlView): TEnrichedRule[] {
  const rules: TEnrichedRule[] = [];

  if ('enableIf' in control && control.enableIf) {
    const enableIf = control.enableIf;
    rules.push(mapEnableIf({ fieldId: control.fieldId, enableIf }));
  }

  if ('valueSource' in control && control.valueSource) {
    const valueSource = control.valueSource;
    rules.push(mapValueSource({ fieldId: control.fieldId, valueSource }));
  }

  if ('restrictions' in control && control.restrictions) {
    const restrictions = control.restrictions;
    rules.push(mapRestrictions({ fieldId: control.fieldId, restrictions }));
  }

  if ('filterOptions' in control && control.filterOptions) {
    const filterOptions = control.filterOptions;
    rules.push(mapFilterOptions({ fieldId: control.fieldId, filterOptionsView: filterOptions }));
  }

  if ('concatination' in control && control.concatination) {
    const concatination = control.concatination;
    rules.push(mapConcatinationRule({ fieldId: control.fieldId, concatinationView: concatination }));
  }

  if ('calculatedValue' in control && control.calculatedValue) {
    const calculatedValue = control.calculatedValue;
    rules.push(mapCalculatedValueRule({ fieldId: control.fieldId, calculatedValueView: calculatedValue }));
  }

  if ('requiredIf' in control && control.requiredIf) {
    const requiredIf = control.requiredIf;
    rules.push(mapRequiredIfRule({ fieldId: control.fieldId, requiredIfView: requiredIf }));
  }

  return rules;
}

// --------------------

export type TEnableIfRule = {
  dependentField: string;
  type: 'enableIf';
  ruleView: TEnableIfView[];
};

export const mapEnableIf = ({
  fieldId,
  enableIf,
}: {
  fieldId: string;
  enableIf: TEnableIfView[];
}): TEnrichedRule<TEnableIfRule> => {
  const managmentControls: string[] = [];

  enableIf.forEach((ruleObj) => {
    managmentControls.push(ruleObj.control);
  });

  return {
    managmentControls,
    rule: {
      type: 'enableIf',
      dependentField: fieldId,
      ruleView: enableIf,
    },
  };
};

// --------------------

export type TValueSourceRule = {
  dependentField?: string;
  type: 'valueSource';
  ruleView: TValueSourceView;
};

export const mapValueSource = ({
  fieldId,
  valueSource,
}: {
  fieldId?: string;
  valueSource: TValueSourceView;
}): TEnrichedRule<TValueSourceRule> => {
  const managmentControls = new Set<string>();

  const getManagmentControlsFromSourceObject = (sourceObj: TDirectorySource): void => {
    if (sourceObj.filter) {
      const { managmentControls: filterManagControls } = mapFilterRule(sourceObj.filter);

      filterManagControls.forEach((c) => managmentControls.add(c));
    }

    if (sourceObj.isSourceFor) {
      return getManagmentControlsFromSourceObject(sourceObj.isSourceFor);
    }
  };

  if (!Array.isArray(valueSource.sources)) {
    if (valueSource.sources.sourceType === 'control') {
      managmentControls.add(valueSource.sources.source);

      if (valueSource.sources.isSourceFor) {
        getManagmentControlsFromSourceObject(valueSource.sources.isSourceFor);
      }
    } else {
      getManagmentControlsFromSourceObject(valueSource.sources);

      if (valueSource.sources.isSourceFor) {
        getManagmentControlsFromSourceObject(valueSource.sources.isSourceFor);
      }
    }
  } else {
    valueSource.sources.forEach((ruleObj) => {
      if (ruleObj.sourceType === 'control') {
        managmentControls.add(ruleObj.source);

        if (ruleObj.isSourceFor) {
          getManagmentControlsFromSourceObject(ruleObj.isSourceFor);
        }
      } else {
        getManagmentControlsFromSourceObject(ruleObj);

        if (ruleObj.isSourceFor) {
          getManagmentControlsFromSourceObject(ruleObj.isSourceFor);
        }
      }
    });
  }

  const data: TEnrichedRule<TValueSourceRule> = {
    managmentControls: Array.from(managmentControls),
    rule: {
      type: 'valueSource',
      ruleView: valueSource,
    },
  };

  if (fieldId) {
    data.rule.dependentField = fieldId;
  }

  return data;
};

// --------------------

export type TRestrictionsRule = {
  type: 'restrictions';
  ruleView: TRestrictions;
};

export const mapRestrictions = ({
  fieldId,
  restrictions,
}: {
  fieldId: string;
  restrictions: TRestrictions;
}): TEnrichedRule<TRestrictionsRule> => {
  return {
    managmentControls: [fieldId],
    rule: {
      type: 'restrictions',
      ruleView: restrictions,
    },
  };
};

// --------------------------------------------------------

export type TFilterOptionsRule = {
  dependentField: string;
  type: 'filterOptions';
  ruleView: TFilterOptionsView;
};

const mapFilterOptions = ({
  fieldId,
  filterOptionsView,
}: {
  fieldId: string;
  filterOptionsView: TFilterOptionsView;
}): TEnrichedRule<TFilterOptionsRule> => {
  const { managmentControls } = mapFilterRule(filterOptionsView.filterRules);

  const data: TEnrichedRule<TFilterOptionsRule> = {
    managmentControls,
    rule: {
      dependentField: fieldId,
      type: 'filterOptions',
      ruleView: filterOptionsView,
    },
  };

  return data;
};

// --------------------------------------------------------------------------

export type TConcatinationRule = {
  dependentField: string;
  type: 'concatination';
  ruleView: TConcatinationView;
};

export const mapConcatinationRule = ({
  fieldId,
  concatinationView,
}: {
  fieldId: string;
  concatinationView: TConcatinationView;
}): TEnrichedRule<TConcatinationRule> => {
  const { managmentControls } = mapValueSource({
    valueSource: concatinationView.value,
  });

  const data: TEnrichedRule<TConcatinationRule> = {
    managmentControls,
    rule: {
      dependentField: fieldId,
      type: 'concatination',
      ruleView: concatinationView,
    },
  };

  return data;
};

// ---------------------------------------------------------

export type TCalculatedValueRule = {
  dependentField: string;
  type: 'calculatedValue';
  ruleView: TCalculatedValueView;
};

export const mapCalculatedValueRule = ({
  fieldId,
  calculatedValueView,
}: {
  fieldId: string;
  calculatedValueView: TCalculatedValueView;
}): TEnrichedRule<TCalculatedValueRule> => {
  const { managmentControls } = mapValueSource({
    valueSource: calculatedValueView.valueSource,
  });

  const data: TEnrichedRule<TCalculatedValueRule> = {
    managmentControls,
    rule: {
      dependentField: fieldId,
      type: 'calculatedValue',
      ruleView: calculatedValueView,
    },
  };

  return data;
};

// --------------------------------------------------------

export type TRequiredIfRule = {
  dependentField: string;
  type: 'requiredIf';
  ruleView: TRequiredIfView;
};

export const mapRequiredIfRule = ({
  fieldId,
  requiredIfView,
}: {
  fieldId: string;
  requiredIfView: TRequiredIfView;
}): TEnrichedRule<TRequiredIfRule> => {
  const { managmentControls } = mapFilterRule(requiredIfView.requirednessRules);

  const data: TEnrichedRule<TRequiredIfRule> = {
    managmentControls,
    rule: {
      dependentField: fieldId,
      type: 'requiredIf',
      ruleView: requiredIfView,
    },
  };

  return data;
};

// ---------------------------------------------------------

const mapFilterRule = (view: TCompareRulesView) => {
  const managmentControls = new Set<string>();

  const processFilterItem = (filterItem: TCompareRulesView | TCompareRuleItem | 'OR' | 'AND'): void => {
    if (typeof filterItem === 'string') {
      return;
    }

    if (Array.isArray(filterItem)) {
      return filterItem.forEach((fi) => processFilterItem(fi));
    }

    if (
      typeof filterItem.compareWith === 'object' &&
      filterItem.compareWith !== null &&
      'type' in filterItem.compareWith &&
      filterItem.compareWith.type === 'valueSource'
    ) {
      const { managmentControls: valueSourceManagmentControls } = mapValueSource({
        valueSource: filterItem.compareWith,
      });

      valueSourceManagmentControls.forEach((mc) => managmentControls.add(mc));
    }

    if (
      typeof filterItem.toCompare === 'object' &&
      filterItem.toCompare !== null &&
      'type' in filterItem.toCompare &&
      filterItem.toCompare.type === 'valueSource'
    ) {
      const { managmentControls: valueSourceManagmentControls } = mapValueSource({
        valueSource: filterItem.toCompare,
      });

      valueSourceManagmentControls.forEach((mc) => managmentControls.add(mc));
    }
  };

  view.forEach(processFilterItem);

  return { managmentControls: Array.from(managmentControls) };
};
