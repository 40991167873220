export type TPrimitive = string | number | boolean;
export type TPrimitiveArray = string[] | number[] | boolean[];

export const isPrimitive = (value: unknown): value is TPrimitive => {
  switch (typeof value) {
    case 'number':
    case 'string':
    case 'boolean': {
      return true;
    }
    default: {
      return false;
    }
  }
};

export const isPrimitivesArray = (value: unknown): value is TPrimitiveArray => {
  if (Array.isArray(value)) {
    return value.every((val) => isPrimitive(val));
  } else {
    return false;
  }
};

export type TOption = {
  label: string;
  value: number;
};
