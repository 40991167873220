import { Button } from '@profgeosoft-ui/react';
import { useTranslation } from 'react-i18next';

import { wrap } from 'src/packages/mobx-di/wrap';

import type { PropsWithChildren } from 'react';

import styles from './combobox-dropdown.module.scss';

type Props = PropsWithChildren<{
  onButtonClick: VoidFunction;
  onClose?(): void;
}>;

export const ComboboxDropdown = wrap<Props>(function ComboboxDropdown({ children, onButtonClick, onClose }) {
  const { t } = useTranslation();

  const handleClick = () => {
    onButtonClick();
    onClose?.();
  };

  return (
    <>
      {children}

      <Button variant="flat" className={styles.button} onClick={handleClick}>
        {t('directory:components.addNewDirectoryButton')}
      </Button>
    </>
  );
});
