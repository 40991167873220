import { assert } from 'src/packages/utils/assert';

import type { GridColumnGroup, GridColumnGroupingModel } from '@profgeosoft-ui/react';
import type { TTableView } from 'src/services/directory-service/types';

import { TableHeader } from './components/table-header';

export function useGetColumnsGrouping(view: TTableView | null): GridColumnGroupingModel {
  if (!view) {
    return [];
  }

  const groups: GridColumnGroup[] = [];

  const innerColumns = view.columns.at(0)?.innerColumns;

  assert(innerColumns?.length, 'inner column are not presented');

  for (const column of innerColumns) {
    if (!('innerColumns' in column)) {
      continue;
    }

    groups.push({
      groupId: column.fieldId,
      freeReordering: true,
      renderHeaderGroup: () => <TableHeader textTKey={`labels:${column.fieldId}.label`} />,
      children: column.innerColumns.map((inCol) => ({ field: inCol.fieldId })),
    });
  }

  return groups;
}
