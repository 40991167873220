import { requireService } from '@profgeosoft/di-ez';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { wrap } from 'src/packages/mobx-di/wrap';

import type { ReactNode } from 'react';

import styles from './menu-item.module.scss';

export type TMenuItem = {
  name: string;
  renderIcon: (className: string) => ReactNode;
  onClick?(): void;
  to?: string;
  notifications?: {
    count: number;
  };
};

type Props = {
  isOpened: boolean;
  item: TMenuItem;
  onClick?(): void;
  isSecondary?: boolean;
  isActive?: boolean;
};

export const MenuItem = wrap<Props>(function MenuItem({ isActive, isOpened, item, isSecondary, onClick }) {
  const themeService = requireService('themeService');
  const { t } = useTranslation('sidebar');

  return (
    <button
      className={clsx(
        styles.wrapper,
        isActive && styles.wrapper__active,
        isSecondary && isActive && styles.wrapper__secondaryActive,
      )}
      disabled={!onClick}
      onClick={onClick}
    >
      <div className={clsx(styles.iconWrapper, isActive && styles.iconWrapper__active)}>
        {!!item?.notifications?.count && <div className={styles.notificationsIconBall} />}
        {item.renderIcon(
          clsx(
            styles.icon,
            isActive && styles.icon__active,
            isSecondary && isActive && styles.icon__secondaryActive,
            themeService.theme === 'dark' && styles.icon__dark,
          ),
        )}
      </div>
      <div className={clsx(styles.textWrapper, isOpened && styles.textWrapper__opened)}>
        <h3
          className={clsx(
            styles.text,
            isActive && styles.text__active,
            isSecondary && isActive && styles.text__secondaryActive,
            isOpened && styles.text__opened,
          )}
        >
          {t(item.name)}
        </h3>
        {!!item?.notifications?.count && (
          <p className={clsx(styles.subTextFont, styles.notificationsCount)}>{item.notifications.count}</p>
        )}
      </div>
    </button>
  );
});
