import { isAxiosError, isApiErrorWithViolations, throwApiError, UpdateObjectListError } from 'src/packages/errors';
import { hasValue } from 'src/packages/utils/has-value';

import type { TRefQuery, TStorageObject } from './directories-storage-service';
import type { AxiosInstance } from 'axios';
import type { TUpdateObjectListViolation } from 'src/packages/errors';

import { StatusCode } from '../../packages/errors/status-code';

type TLoadObjectsParams = {
  withDeleted?: boolean;
  offset?: number;
  limit?: number;
  filterMap?: Record<string, unknown>;
};

export class DirectoriesStorageServiceApi {
  private readonly agent: AxiosInstance;

  constructor(agent: AxiosInstance) {
    this.agent = agent;
  }

  async loadObjects<TStorageObjectData extends Record<string, unknown>>(
    objectName: string,
    params?: TLoadObjectsParams,
    signal?: AbortSignal,
  ): Promise<TStorageObject<TStorageObjectData>[]> {
    const withDeleted = hasValue(params?.withDeleted) ? params?.withDeleted : true;

    try {
      const { data } = await this.agent.get<TStorageObject<TStorageObjectData>[]>(
        `object/list/${objectName}${withDeleted ? '?DELETED=true' : ''}`,
        {
          signal,
          params: {
            ...params?.filterMap,
          },
        },
      );

      return data;
    } catch (e) {
      throwApiError(e);
    }
  }

  async loadObjectById(objectName: string, id: number, signal?: AbortSignal): Promise<TStorageObject> {
    try {
      const { data } = await this.agent.get<TStorageObject>(`object/${objectName}/${id}`, {
        signal: signal,
      });

      return data;
    } catch (e) {
      throwApiError(e);
    }
  }

  async createObject(objectName: string, objectData: unknown, signal?: AbortSignal): Promise<{ id: number }> {
    try {
      const { data } = await this.agent.post<{ id: number }>(`object/new/${objectName}`, objectData, { signal });

      return data;
    } catch (e) {
      throwApiError(e);
    }
  }

  async updateObject(
    objectName: string,
    id: number,
    objectData: unknown,
    signal?: AbortSignal,
  ): Promise<{ id: number }> {
    try {
      const { data } = await this.agent.patch<{ id: number }>(`object/${objectName}/${id}`, objectData, { signal });

      return data;
    } catch (e) {
      throwApiError(e);
    }
  }

  async updateStatus(
    objectName: string,
    id: number,
    status: 'ACTIVE' | 'DELETED',
    signal?: AbortSignal,
  ): Promise<void> {
    try {
      await this.agent.patch(`object/${objectName}/${id}/status`, status, { signal });
    } catch (e) {
      throwApiError(e);
    }
  }

  async loadByRefQuery(refQuery: TRefQuery, signal?: AbortSignal): Promise<Record<string, TStorageObject[]>> {
    try {
      const { data } = await this.agent.post<Record<string, TStorageObject[]>>('object/select', refQuery, { signal });

      return data;
    } catch (e) {
      throwApiError(e);
    }
  }

  async updateObjectList(
    objectName: string,
    list: (Partial<TStorageObject> & { data: Record<string, unknown> })[],
    signal?: AbortSignal,
  ): Promise<void> {
    try {
      return await this.agent.post(`object/list/${objectName}`, list, { signal });
    } catch (e) {
      if (
        isAxiosError(e) &&
        e.response &&
        isApiErrorWithViolations<TUpdateObjectListViolation>(e.response.data) &&
        hasValue(e.response.status) &&
        StatusCode[e.response.status]
      ) {
        const message =
          'message' in e.response.data && typeof e.response.data.message === 'string' ? e.response.data.message : '';

        throw new UpdateObjectListError(e.response.status, message, e.response.data.violations, e);
      }

      throwApiError(e);
    }
  }
}
