import { copyDeep } from 'src/packages/utils/copy-deep';

import type { TControlView } from '../types/controls-views.type';
import type { TTableView, TComplexFormView } from '../types/views.type';

const isTableView = (view: Object): view is TTableView => {
  return 'columns' in view;
};

export const getControlsViews = (
  view: TTableView | TComplexFormView,
): { recordControls: TControlView[]; topControls: TControlView[] } => {
  const recordControls: TControlView[] = [];
  const topControls: TControlView[] = [];

  if (isTableView(view)) {
    if (view.controls) {
      if (view.controls.filters) {
        for (const control of view.controls.filters) {
          topControls.push(copyDeep(control));
        }
      }
    }

    for (const column of view.columns) {
      for (const innerColumn of column.innerColumns) {
        if ('innerColumns' in innerColumn) {
          for (const control of innerColumn.innerColumns) {
            recordControls.push(copyDeep(control));
          }
          continue;
        }

        recordControls.push(copyDeep(innerColumn));
      }
    }
  } else {
    for (const group of view.groups) {
      for (const column of group.columns) {
        for (const control of column.rows) {
          recordControls.push(copyDeep(control));
        }
      }
    }
  }

  return { recordControls, topControls };
};
