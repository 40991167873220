import { requireService } from '@profgeosoft/di-ez';
import { action, flow, makeObservable, observable } from 'mobx';

import { createPromiseController, type TPromiseController } from 'src/packages/utils/promise-controller';

import type { TAppLanguage } from '../localization-service.ts/types';

import { PRIMARY_LANG } from '../localization-service.ts/localization.consts';

export class DirectoryPageHeaderService {
  private confirmationPromise: TPromiseController<{ isOk: boolean }> | null = null;

  @observable isConfirmationModalOpen = false;

  @observable isDisplaySettingsOpen: boolean = false;

  @observable searchText: string = '';
  @observable selectedLanguages: TAppLanguage[] = [PRIMARY_LANG];
  @observable selectedYear: number | null = null;

  constructor(
    private readonly directoryListService = requireService('directoriesListService'),
    private readonly directoryPage = requireService('directoryPage'),
  ) {
    makeObservable(this);
  }

  @flow.bound
  async *onEditModeOffByDiscard(onDiscardChanges: VoidFunction): Promise<void> {
    try {
      this.confirmationPromise = createPromiseController();
      this.isConfirmationModalOpen = true;

      const { isOk } = await this.confirmationPromise;
      yield;

      if (isOk) {
        onDiscardChanges();
        this.directoryListService.setIsDirectoryChangingBlocked(false);
        this.directoryPage.setIsEditMode(false);
        this.directoryPage.setIsRowSelectionMode(false);
      }
    } catch (error) {
      yield;
      console.error(error);
    }

    this.isConfirmationModalOpen = false;
    this.confirmationPromise = null;
  }

  onConfirmationOk = (): void => {
    this.confirmationPromise?.resolve({ isOk: true });
  };

  onConfirmationCancel = (): void => {
    this.confirmationPromise?.resolve({ isOk: false });
  };

  @action.bound
  onDisplaySettingsClick(value: boolean) {
    this.isDisplaySettingsOpen = value;
  }

  @action.bound
  onSearchTextChange(text: string): void {
    this.searchText = text;
  }

  @action.bound
  onLanguageFilterChange(value: TAppLanguage[]): void {
    this.selectedLanguages = value;
  }
}
