import { SignInSqureLight as LogoutIcon, ProfilePhoto } from '@profgeosoft-ui/icons'; // TODO: add correct icon after redeploing icons package
import clsx from 'clsx';

import { wrap } from 'src/packages/mobx-di/wrap';

import type { UserInfo } from 'src/services/auth-service/user-service';

import styles from './user-section.module.scss';

type Props = {
  isOpened: boolean;
  onLogout: VoidFunction;
  userInfo: UserInfo | null;
};

export const UserSection = wrap(function UserSection({ isOpened, userInfo, onLogout }: Props) {
  const name = userInfo?.name || userInfo?.preferred_username;
  return (
    <div className={styles.wrapper}>
      <div className={styles.photoWrapper}>
        <ProfilePhoto className={styles.photo} alt="avatar" />
      </div>
      <div className={clsx(styles.nameAndLogoutWrapper, isOpened && styles.nameAndLogoutWrapper__opened)}>
        <p className={clsx(styles.text)}>{name}</p>

        <button className={styles.logoutButton} onClick={onLogout}>
          <LogoutIcon />
        </button>
      </div>
    </div>
  );
});
