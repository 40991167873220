import { action, flow, makeObservable, observable } from 'mobx';

import type { AxiosInstance } from 'axios';

import { ViewsApi } from './view-service-api';

export interface IViewService<TViewTypes = never> {
  getView<TView extends {}>(name: string, type: TViewTypes): View<TView>;
}

export class View<TView = {}> {
  private readonly _loadView: (signal?: AbortSignal) => Promise<TView>;
  private abortController: AbortController | null = null;
  private _loadingView: Promise<TView> | null = null;

  @observable view: TView | null = null;
  @observable isViewFetched = false;
  @observable isLoading = false;

  constructor(loadView: (signal?: AbortSignal) => Promise<TView>) {
    this._loadView = loadView;

    makeObservable(this);
  }

  get loadingView(): Promise<TView> | null {
    return this._loadingView;
  }

  @flow.bound
  async *loadView(): Promise<TView> {
    if (this.view) {
      return this.view;
    }

    if (this._loadingView) {
      return this._loadingView;
    }

    this.abortController = new AbortController();

    try {
      this.isLoading = true;

      this._loadingView = this._loadView(this.abortController.signal);

      const view = await this._loadingView;
      yield;

      this.view = view;
      this.isViewFetched = true;
      this._loadingView = null;

      return this.view;
    } finally {
      yield;
      this._loadingView = null;
      this.isLoading = false;
      this.abortController = null;
    }
  }

  @action.bound
  cancelLoading(): void {
    this.abortController?.abort();
    this.abortController = null;
  }
}

export class ViewsService<TViewTypes = never> implements IViewService<TViewTypes> {
  private api: ViewsApi<TViewTypes>;
  private views = new Map<string, View>();

  constructor(agent: AxiosInstance) {
    this.api = new ViewsApi<TViewTypes>(agent);
  }

  getView<TView extends {}>(name: string, type: TViewTypes): View<TView> {
    const viewName = `${type}.${name}`;

    const savedView = this.views.get(viewName);
    if (savedView) {
      return savedView as View<TView>;
    }

    const newView = new View<TView>((signal?: AbortSignal) => this.api.getView<TView>(name, type, signal));

    this.views.set(viewName, newView);

    return newView;
  }
}
