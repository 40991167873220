import { Tooltip } from '@profgeosoft-ui/react';
import clsx from 'clsx';

import { wrap } from 'src/packages/mobx-di/wrap';

import type { TooltipProps } from '@profgeosoft-ui/react/dist/components/tooltip/tooltip';
import type { CSSProperties, PropsWithChildren, ReactNode } from 'react';

import styles from './cell.module.scss';

export interface CellProps {
  message?: ReactNode;
  tooltipProps?: Omit<TooltipProps<HTMLDivElement>, 'title'>;
  style?: CSSProperties;
}

export const Cell = wrap<PropsWithChildren<CellProps>>(function Cell({ children, message, tooltipProps, style }) {
  if (message) {
    return (
      <Tooltip title={message} useSafePolygon={false} placement="top" canTooltipBeFocused={false} {...tooltipProps}>
        <div className={clsx(styles.cell)} style={style}>
          {children}
        </div>
      </Tooltip>
    );
  }

  return (
    <div className={clsx(styles.cell)} style={style}>
      {children}
    </div>
  );
});
