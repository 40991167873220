import { useTranslation } from 'react-i18next';

import HintArrowImage from 'src/packages/assets/images/hint-arrow.svg?react';
import { wrap } from 'src/packages/mobx-di/wrap';

import styles from './choose-required-values-hint.module.scss';

export const ChooseRequiredValuesHint = wrap(function ChooseRequiredValuesHint() {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <HintArrowImage className={styles.image} />

      <p>{t('directory:errors.view.generalControlsNotFilledIn')}</p>
    </div>
  );
});
