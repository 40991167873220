import { BackLight } from '@profgeosoft-ui/icons';
import { Button, Loader, Scrollbar } from '@profgeosoft-ui/react';
import { requireService } from '@profgeosoft/di-ez';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { wrap } from 'src/packages/mobx-di/wrap';

import styles from './models-list.module.scss';

export const ModelsList = wrap(function ModelsList() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { modelId } = useParams();
  const directoriesList = requireService('directoriesListService');
  const directoryPage = requireService('directoryPage');

  const { directoryModelService, isEditing } = directoryPage;
  const view = directoryModelService?.view;

  const headingText = view ? t(`labels:${view.mainDisplayAttr}.label`) : '';

  const onBack = () => {
    if (!directoriesList.currentDirectory) {
      navigate('/');
      return;
    }

    navigate(`/${directoriesList.currentDirectory}`);
  };

  const onModelClick = (modelId: number) => {
    if (modelId === directoryModelService?.currentModelId) {
      return;
    }

    directoryModelService?.setCurrentModel(modelId);
    navigate(modelId.toString());
  };

  useEffect(() => {
    return directoryModelService?.init();
  }, [directoryModelService]);

  useEffect(() => {
    if (!modelId && directoryModelService && directoryModelService.modelsList.length) {
      const defaultModelId = directoryModelService.modelsList[0].value;
      directoryModelService.setCurrentModel(defaultModelId);
      navigate(defaultModelId.toString());
      return;
    }

    if (directoryModelService && directoryModelService.modelsList.length) {
      const modelIdAsNumber = Number(modelId);
      const isExisting = !!directoryModelService.modelsList.find((model) => model.value === modelIdAsNumber);

      if (isExisting) {
        directoryModelService.setCurrentModel(modelIdAsNumber);
      } else {
        directoryModelService.setCurrentModel(null);
        navigate('./');
      }
    }
  }, [modelId, directoryModelService, directoryModelService?.modelsList, navigate]);

  return (
    <>
      <div className={styles.wrapper}>
        {!directoryModelService || directoryModelService.isModelsLoading ? (
          <Loader />
        ) : (
          <>
            <h4 className={styles.heading}>{headingText}</h4>
            <div className={styles.listWrapper}>
              <Scrollbar>
                <ul className={styles.list}>
                  {directoryModelService.modelsList.map(({ label, value }) => {
                    const isActive = value === directoryModelService.currentModelId;

                    return (
                      <button
                        key={value}
                        className={clsx(styles.listButton, isActive && styles.listButton_active)}
                        disabled={isEditing}
                        onClick={() => onModelClick(value)}
                      >
                        <p className={styles.listButton_text} title={label}>
                          {label}
                        </p>
                      </button>
                    );
                  })}
                </ul>
              </Scrollbar>
            </div>
          </>
        )}
        <Button className={styles.backButton} icon={<BackLight />} disabled={isEditing} onClick={onBack}>
          {t('common:back')}
        </Button>
      </div>
      <div className={styles.tableWrapper}>
        <Outlet />
      </div>
    </>
  );
});
