import { DarhboardLight as DashboardIcon, BookCheckFill as LibrariesIcon } from '@profgeosoft-ui/icons';
import { DASHBOARD_URL } from '../consts';

export const navProps = [
  {
    name: 'sidebar:nav.libraries',
    to: '/',
    renderIcon: (className: string) => <LibrariesIcon className={className} />,
  },
  {
    name: 'sidebar:nav.dashboard',
    to: DASHBOARD_URL,
    isExternalLink: true,
    renderIcon: (className: string) => <DashboardIcon className={className} />,
  },
];
