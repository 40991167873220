import { action, flow, makeObservable, observable } from 'mobx';

import { OperationAbortedError, ApiError } from 'src/packages/errors';
import { isPrimitive } from 'src/packages/types';
import { hasValue } from 'src/packages/utils/has-value';

import type { IControl } from './types';
import type { TComboBoxView, TControlView, TMultiComboBoxView } from './types/controls-views.type';
import type { TRefQuery } from '../directories-storage-service/types';
import type { NotificationsService } from '../notifications-service';

import {
  REF_QUERY_REG_EXP,
  type IDirectoriesStorage,
  type TStorageObject,
} from '../directories-storage-service/directories-storage-service';

type TOption = {
  label: string;
  value: number;
};

type TDeps = {
  fieldId: string[];
  attrs: string[];
  outer: string[];
};

export const getDynamicJoinDependencies = (refQuery: TRefQuery): TDeps => {
  const dependencies: TDeps = {
    fieldId: [],
    attrs: [],
    outer: [],
  };

  const stringifiedRefQuery = JSON.stringify(refQuery);

  const values = Array.from(stringifiedRefQuery.matchAll(REF_QUERY_REG_EXP));

  for (const value of values) {
    const type = value[1];
    const valueKey = value[2];
    switch (type) {
      // Values are obtained from controls
      case '$': {
        dependencies.attrs.push(`$${valueKey}`);
        break;
      }
      // Values are obtained from directories
      case '%': {
        dependencies.fieldId.push(`%${valueKey}`);
        break;
      }
      //  Values are obtained from outer object of global variables
      case '@': {
        dependencies.outer.push(`@${valueKey}`);
        break;
      }
    }
  }

  return dependencies;
};

function createOptionsFromObjects(
  attrName: string,
  objects: TStorageObject<Record<string, unknown>>[],
): { options: TOption[]; archivedOptions: TOption[] } {
  const options: TOption[] = [];
  const archivedOptions: TOption[] = [];

  for (const object of objects) {
    const isArchived = object.status === 'DELETED';

    const label = ((): string | null => {
      const labelValue = object.data[attrName];

      if (isPrimitive(labelValue)) {
        return labelValue.toString();
      }

      return null;
    })();

    if (!label) {
      continue;
    }

    if (isArchived) {
      archivedOptions.push({ label, value: object.id });
    } else {
      options.push({ label, value: object.id });
    }
  }

  return { options, archivedOptions };
}

export class OptionsService {
  private readonly directoriesStorageService: IDirectoriesStorage;
  private readonly notificationsService: NotificationsService;

  @observable private objectSubscriptionDisposers: VoidFunction[] = [];
  @observable readonly options: Record<string, TOption[]> = {};
  @observable readonly archivedOptions: Record<string, TOption[]> = {};

  constructor(notificationsService: NotificationsService, directoriesStorageService: IDirectoriesStorage) {
    this.notificationsService = notificationsService;
    this.directoriesStorageService = directoriesStorageService;
    makeObservable(this);
  }

  @action.bound
  createInitialOptions(controlsViews: TControlView[]): void {
    this.objectSubscriptionDisposers.forEach((disposer) => disposer());
    this.objectSubscriptionDisposers = [];

    for (const control of controlsViews) {
      if ('refObjectAttr' in control && 'refObjectType' in control) {
        const attr = control.refObjectAttr;
        const type = control.refObjectType;

        if (attr && type) {
          const updateOptions = (objects: TStorageObject[]) => {
            const { options, archivedOptions } = createOptionsFromObjects(attr, objects);
            this.setOptions(control.fieldId, options);
            this.setArchivedOptions(control.fieldId, archivedOptions);
          };

          const objects = this.directoriesStorageService.directories[type] ?? [];
          updateOptions(objects);

          // Subscribe to the list of objects in the desired directory in order to respond to changes in the composition of this list
          const disposer = this.directoriesStorageService.subscribeOnObjectsChange(type, updateOptions);

          this.objectSubscriptionDisposers.push(disposer);
        }
      }
    }
  }

  @flow.bound
  async *getOptionsByRefQuery(
    controlView: TComboBoxView | TMultiComboBoxView,
    controls: IControl[],
    signal?: AbortSignal,
  ): Promise<TOption[]> {
    const refQuery = controlView.refQuery;

    if (!refQuery) {
      console.warn('ref query is not presented');
      return [];
    }

    const options: TOption[] = [];

    try {
      const dependencies = getDynamicJoinDependencies(refQuery);
      const dependenciesObject: Record<string, unknown> = {};

      for (const dep of dependencies.fieldId) {
        const key = dep.slice(1);
        const value = controls.find((control) => control.fieldId === key)?.value;

        if (hasValue(value)) {
          dependenciesObject[dep] = value;
        }
      }

      for (const dep of dependencies.attrs) {
        const key = dep.slice(1);
        const value = controls.find((control) => control.attrName === key)?.value;

        if (hasValue(value)) {
          dependenciesObject[dep] = value;
        }
      }

      const objectsArr = await this.directoriesStorageService.loadObjectsByRefQuery<Record<string, unknown>>(
        refQuery,
        dependenciesObject,
        signal,
      );

      if (!objectsArr) {
        return [];
      }

      for (const objects of objectsArr) {
        const mainObject = objects?.[controlView.refObjectType];

        if (!mainObject) {
          continue;
        }

        const value = mainObject.id;

        if ('concatination' in controlView) {
          // TODO: обработать конкатенацию
        }

        const label = mainObject.data[controlView.refObjectAttr];

        if (isPrimitive(label)) {
          options.push({
            label: label.toString(),
            value,
          });
        } else {
          console.warn(`invalid label for type: ${controlView.refObjectType}, attr: ${controlView.refObjectAttr}`);
        }
      }

      return options;
    } catch (e) {
      yield;

      if (e instanceof OperationAbortedError) {
        return;
      }

      console.error(e);

      if (e instanceof ApiError && e.message) {
        this.notificationsService.showErrorMessage(e.message);
        return;
      }

      this.notificationsService.showErrorMessageT('directory:errors.data.failedToLoadData');
    }

    return options;
  }

  @action.bound
  setOptions(attrName: string, options: TOption[]): void {
    this.options[attrName] = options;
  }

  @action.bound
  setArchivedOptions(attrName: string, options: TOption[]): void {
    this.archivedOptions[attrName] = options;
  }

  init = (): VoidFunction => {
    return () => {
      this.objectSubscriptionDisposers.forEach((disposer) => disposer());
    };
  };
}
