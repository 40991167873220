import { action, flow, makeObservable, observable, runInAction } from 'mobx';

import { ApiError, OperationAbortedError } from 'src/packages/errors';
import { isLanguage } from 'src/packages/utils/get-locale';

import type { TAppLanguage } from './types';
import type { NotificationsService } from '../notifications-service';
import type { AxiosInstance } from 'axios';
import type { i18n as Ii18, TFunction, TOptions } from 'i18next';
import type { LANGUAGES } from 'src/packages/utils/get-locale';

import i18n from './i18';
import { I18Api } from './localization.api';
import { languageSwitcherItems, PRIMARY_LANG, SECONDARY_LANG } from './localization.consts';

export interface Ii18Service {
  languages: string[];
  language: string;
  t(key: string, options?: TOptions): string;
  setLocale(locale: TAppLanguage): void;
}

export class LocalizationService implements Ii18Service {
  private readonly i18: Ii18;
  private readonly api: I18Api;

  @observable language: string;
  @observable t: TFunction;

  constructor(
    goStorageAgent: AxiosInstance,
    private readonly notifications: NotificationsService,
  ) {
    this.api = new I18Api(goStorageAgent);
    this.language = i18n.language;

    this.i18 = i18n;
    this.t = i18n.t;

    makeObservable(this);
  }

  get i18n(): Ii18 {
    return this.i18;
  }

  get languages(): string[] {
    const languages = [languageSwitcherItems[PRIMARY_LANG]];

    if (SECONDARY_LANG && !!languageSwitcherItems[SECONDARY_LANG]) {
      languages.push(languageSwitcherItems[SECONDARY_LANG]);
    }

    return languages;
  }

  getCertainLocaleInAllLanguages(key: `${string}:${string}`): Record<string, string> {
    const result: Record<string, string> = {};

    const languages = Object.keys(this.i18.store.data);

    for (const lang of languages) {
      const t = this.i18.getFixedT(lang);

      if (this.i18.exists(key, { lng: lang })) {
        result[lang] = t(key);
      }
    }

    return result;
  }

  @flow.bound
  private async *loadLables(languages: string[]): Promise<void> {
    try {
      const labels = await this.api.fetchSettingsLabels(languages);
      yield;

      labels.forEach(({ key, value }) => {
        this.i18.addResourceBundle(key, 'labels', value);
      });
    } catch (error) {
      if (error instanceof OperationAbortedError) return;

      console.error(error);
      if (error instanceof ApiError && error.message) {
        this.notifications.showErrorMessage(error.message);
        return;
      }

      this.notifications.showErrorMessageT('errors:receivingLabels');
    }
  }

  @action.bound
  setLocale(locale: TAppLanguage): void {
    this.i18.changeLanguage(locale).then(() => {
      this.t = this.i18.t;
      runInAction(() => {
        this.language = this.i18.language;
      });
    });
  }

  init = async () => {
    try {
      const languages = [PRIMARY_LANG, SECONDARY_LANG].filter((value): value is LANGUAGES => isLanguage(value));

      await this.loadLables(languages);
    } catch (error) {
      console.error(error);
    }
  };
}
