import type { AxiosInstance } from 'axios';

type TValidateObjectData = { id?: number; data: Record<string, unknown> };

export class ValidationServiceApi {
  private readonly agent: AxiosInstance;

  constructor(agent: AxiosInstance) {
    this.agent = agent;
  }
}
