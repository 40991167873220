import { requireService } from '@profgeosoft/di-ez';
import { action, flow, makeObservable, observable, reaction } from 'mobx';

import { agent } from 'src/api/agent';
import { DirectoryModelService } from 'src/features/models-list/directory-model-service';
import { ApiError, OperationAbortedError } from 'src/packages/errors';
import { debounce } from 'src/packages/utils/debounce';
import { hasValue } from 'src/packages/utils/has-value';
import { localStorageUtil } from 'src/packages/utils/local-storage-util';
import { AdditionalDirectoryService, MainDirectoryService } from 'src/services/directory-service';
import { serializeDirectoryRecordToSave } from 'src/services/directory-service/directory-service.utils';
import { TableSettingsService } from 'src/services/table-settings-service/table-settings-service';

import type {
  TComplexFormView,
  TFormView,
  TSimpleFormView,
  TTableView,
} from 'src/services/directory-service/types/views.type';

export type TSavedEditModeObject = {
  directory: string;
  subDirectory: string | null;
  modelId: number | null;
  newRecords: Record<string, unknown>[];
  updatedRecords: Record<number, Record<string, unknown>>;
};

export interface IEditModeDataManager {
  saveEditModeData: VoidFunction;
  getSavedEditModeData: () => TSavedEditModeObject | null;
}

export const EDIT_MODE_DATA_KEY = 'savedEditModeData';

const isSimpleForm = (view: TFormView): view is TSimpleFormView => {
  return !!view && 'popup' in view;
};

export class DirectoryPageService {
  readonly tableSettings: TableSettingsService;
  @observable private mainDirectoryAbortFn: VoidFunction | null = null;

  @observable directoryModelService: DirectoryModelService | null = null;
  @observable currentDirectory: MainDirectoryService | null = null;
  @observable additionalDirectories: AdditionalDirectoryService[] = [];
  @observable isEditing = false;
  @observable isRowSelectionModeOn: boolean = false;
  @observable isAdditionalDirectoryInitializing = false;
  @observable isMainDirectoryInitialized = false;

  constructor(
    private readonly viewService = requireService('viewsService'),
    private readonly directoriesList = requireService('directoriesListService'),
    private readonly notificationsService = requireService('notificationsService'),
    private readonly directoriesStorageService = requireService('directoriesStorage'),
    private readonly localizationService = requireService('localizationService'),

    userService = requireService('userService'),
  ) {
    this.tableSettings = new TableSettingsService(agent, userService, notificationsService);

    makeObservable(this);
  }

  private saveEditModeData(): void {
    const newRecords = this.currentDirectory?.newRecords ?? [];
    const updatedRecords = this.currentDirectory?.updatedRecords ?? [];

    const directory = this.directoriesList.currentDirectory;
    const subDirectory = this.directoriesList.currentSubDirectory;
    const modelId = this.directoryModelService?.currentModelId ?? null;

    if (!hasValue(directory)) {
      console.warn('current directory name is not presented');
      return;
    }

    const data: TSavedEditModeObject = {
      directory,
      subDirectory,
      modelId,
      newRecords: newRecords.map((record) => serializeDirectoryRecordToSave(record)),
      updatedRecords: updatedRecords.reduce((acc: Record<number, Record<string, unknown>>, record) => {
        acc[record.recordId] = serializeDirectoryRecordToSave(record);
        return acc;
      }, {}),
    };

    localStorageUtil.set(EDIT_MODE_DATA_KEY, data);
  }

  @action.bound
  setIsRowSelectionMode(value: boolean): void {
    this.isRowSelectionModeOn = value;
  }

  private getSavedEditModeData(): TSavedEditModeObject | null {
    const rawSavedEditModeData = localStorageUtil.get<TSavedEditModeObject>(EDIT_MODE_DATA_KEY);

    if (
      !rawSavedEditModeData ||
      (this.currentDirectory?.directoryName !== rawSavedEditModeData?.directory &&
        this.currentDirectory?.directoryName !== rawSavedEditModeData?.subDirectory)
    ) {
      return null;
    }

    if (hasValue(rawSavedEditModeData.modelId)) {
      if (!this.directoryModelService || rawSavedEditModeData.modelId !== this.directoryModelService.currentModelId) {
        return null;
      }
    }

    return rawSavedEditModeData;
  }

  get editModeDataManager(): IEditModeDataManager {
    return {
      saveEditModeData: debounce(this.saveEditModeData.bind(this), 500),
      getSavedEditModeData: this.getSavedEditModeData.bind(this),
    };
  }

  @flow.bound
  private async *initializeMainDirectory({
    directory,
    subDirectory,
  }: {
    directory: string | null;
    subDirectory: string | null;
  }) {
    this.isMainDirectoryInitialized = false;

    const directoryName = subDirectory ?? directory;
    const directoryType = subDirectory ? 'subDirectory' : 'default';

    this.currentDirectory = null;

    if (!directoryName) {
      return;
    }

    this.mainDirectoryAbortFn?.();

    const viewInst = this.viewService.getView<TTableView>(directoryName, 'table');
    this.mainDirectoryAbortFn = viewInst.cancelLoading;

    try {
      const view = await viewInst.loadView();
      yield;

      const directoryInst = new MainDirectoryService(
        this.editModeDataManager,
        this.directoriesStorageService,
        this.tableSettings,
        this.notificationsService,
        this.localizationService,
        directoryName,
        view,
      );
      this.currentDirectory = directoryInst;

      await directoryInst.initializeControls();
      yield;

      if (directoryType === 'default') {
        directoryInst.loadData();
      }

      if (directoryType === 'subDirectory') {
        this.directoryModelService = new DirectoryModelService(
          this.notificationsService,
          this.directoriesList,
          this.directoriesStorageService,
          directoryInst,
        );
      } else {
        this.directoryModelService = null;
      }

      this.isMainDirectoryInitialized = true;
    } catch (e) {
      yield;

      if (e instanceof OperationAbortedError) {
        return;
      }

      console.error(e);

      if (e instanceof ApiError && e.message) {
        this.notificationsService.showErrorMessage(e.message);
        return;
      }

      this.notificationsService.showErrorMessageT('directory:errors.view.failedToLoadView');
    } finally {
      this.mainDirectoryAbortFn = null;
    }
  }

  removeSavedEditModeData(): void {
    localStorageUtil.delete(EDIT_MODE_DATA_KEY);
  }

  @action.bound
  addAdditionalDirectory(directoryName: string, view: TComplexFormView): void {
    const directory = new AdditionalDirectoryService(
      this.directoriesStorageService,
      this.notificationsService,
      this.localizationService,
      directoryName,
      view,
    );

    this.additionalDirectories = [...this.additionalDirectories, directory];
  }

  @flow.bound
  async *createNewRelatedRecord(objectName: string): Promise<void> {
    const viewInst = this.viewService.getView<TFormView>(objectName, 'form');
    this.isAdditionalDirectoryInitializing = true;

    try {
      const view = await viewInst.loadView();
      yield;

      if (isSimpleForm(view)) {
        // TODO: здесь должна быть обработка односвойственных директорий (быстрое создание записи из выпадашки комбобокса)
        return;
      } else {
        this.addAdditionalDirectory(objectName, view);
      }
    } catch (e) {
      yield;

      if (e instanceof OperationAbortedError) {
        return;
      }

      console.error(e);

      if (e instanceof ApiError && e.message) {
        this.notificationsService.showErrorMessage(e.message);
        return;
      }

      this.notificationsService.showErrorMessageT('directory:errors.view.failedToLoadView');
    } finally {
      this.isAdditionalDirectoryInitializing = false;
    }
  }

  @action.bound
  setIsEditMode(isEditing: boolean): void {
    this.isEditing = isEditing;
  }

  @action.bound
  removeLastAdditionalDirectory(): void {
    this.additionalDirectories = this.additionalDirectories.slice(0, this.additionalDirectories.length - 1);
  }

  init = () => {
    this.tableSettings.init();

    const directoryServiceDisposer = reaction(
      () => ({
        directory: this.directoriesList.currentDirectory,
        subDirectory: this.directoriesList.currentSubDirectory,
      }),
      this.initializeMainDirectory,
      { fireImmediately: true, name: 'mainDirectoryInitializer' },
    );

    const saveEditModeDataDisposer = reaction(
      () => ({ newRecords: this.currentDirectory?.newRecords, updatedRecords: this.currentDirectory?.updatedRecords }),
      () => {
        if (!this.isEditing || !this.currentDirectory?.records.length) {
          return;
        }

        this.editModeDataManager.saveEditModeData();
      },
    );

    return () => {
      directoryServiceDisposer();
      saveEditModeDataDisposer();
      this.mainDirectoryAbortFn?.();
    };
  };
}
