import { requireService } from '@profgeosoft/di-ez';
import { useTranslation } from 'react-i18next';

import { CheckboxComponent } from 'src/components/controls/checkbox-component';
import { ComboboxComponent } from 'src/components/controls/combobox-component';
import { DateOnlyPickerComponent } from 'src/components/controls/date-only-picker-component';
import { LabelComponent } from 'src/components/controls/label-component';
import { MulticomboboxComponent } from 'src/components/controls/multicombobox-component';
import { NumberInput } from 'src/components/controls/number-input';
import { StringInput } from 'src/components/controls/string-input';
import { YearOnlyPickerComponent } from 'src/components/controls/year-only-picker-component';
import { wrap } from 'src/packages/mobx-di/wrap';
import { CheckboxField } from 'src/services/directory-service/entities/controls/checkbox-field';
import { ComboboxField } from 'src/services/directory-service/entities/controls/combobox-field';
import { DateOnlyPickerField } from 'src/services/directory-service/entities/controls/date-only-picker';
import { LabelField } from 'src/services/directory-service/entities/controls/label-field';
import { MultiComboboxField } from 'src/services/directory-service/entities/controls/multicombobox-field';
import { NumberField } from 'src/services/directory-service/entities/controls/number-field';
import { StringField } from 'src/services/directory-service/entities/controls/string-field';
import { YearOnlyPickerField } from 'src/services/directory-service/entities/controls/year-only-picker';

import type { TOption } from 'src/packages/types';
import type { OptionsService } from 'src/services/directory-service/options-service';
import type { IControl, TControlView } from 'src/services/directory-service/types';

import styles from './form-control.module.scss';

type Props = {
  controlView: TControlView;
  control: IControl;
  controls: IControl[];
  optionsService: OptionsService;
  withLabel?: boolean;
  onChange(control: IControl, value: unknown): void;
  onBlur?(control: IControl): void;
};

export const FormControl = wrap<Props>(function FormControl({
  controlView,
  control,
  controls,
  optionsService,
  withLabel = true,
  onChange,
  onBlur,
}) {
  const { t } = useTranslation();

  const directoryPage = requireService('directoryPage');
  const fieldTitle = t(`labels:${controlView.fieldId}.label`);

  const loadOptionsByRefQuery = async (signal?: AbortSignal): Promise<TOption[]> => {
    if (!('refQuery' in controlView)) {
      return [];
    }

    if (!controlView || !controlView.refQuery) {
      return [];
    }

    const options = await optionsService.getOptionsByRefQuery(controlView, controls, signal);

    return options;
  };

  const getControlComponent = () => {
    if (control instanceof StringField) {
      return <StringInput control={control} onChange={onChange} onBlur={onBlur} />;
    }

    if (control instanceof NumberField) {
      return <NumberInput control={control} onChange={onChange} onBlur={onBlur} />;
    }

    if (control instanceof ComboboxField && controlView.control === 'ComboBox') {
      return (
        <ComboboxComponent
          control={control}
          optionsService={optionsService}
          controlView={controlView}
          loadOptionsFn={loadOptionsByRefQuery}
          onCreateNewRecord={() => directoryPage.createNewRelatedRecord(controlView.refObjectType)}
          onChange={onChange}
          onBlur={onBlur}
        />
      );
    }

    if (control instanceof MultiComboboxField && controlView.control === 'MultiComboBox') {
      return (
        <MulticomboboxComponent
          control={control}
          optionsService={optionsService}
          controlView={controlView}
          loadOptionsFn={loadOptionsByRefQuery}
          onCreateNewRecord={() => directoryPage.createNewRelatedRecord(controlView.refObjectType)}
          onChange={onChange}
          onBlur={onBlur}
        />
      );
    }

    if (control instanceof YearOnlyPickerField) {
      return <YearOnlyPickerComponent control={control} onChange={onChange} onBlur={onBlur} />;
    }

    if (control instanceof DateOnlyPickerField) {
      return <DateOnlyPickerComponent control={control} onChange={onChange} onBlur={onBlur} />;
    }

    if (control instanceof CheckboxField) {
      return <CheckboxComponent control={control} onChange={onChange} />;
    }

    if (control instanceof LabelField) {
      return <LabelComponent control={control} optionsService={optionsService} />;
    }

    return null;
  };

  if (!withLabel) {
    return getControlComponent();
  }

  const requirednessSymbol = control.isRequired ? '*' : '';

  return (
    <div className={styles.wrapper}>
      <p className={styles.label}>{`${fieldTitle}${requirednessSymbol}`}</p>
      {getControlComponent()}
    </div>
  );
});
