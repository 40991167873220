import { observable, makeObservable, computed, action } from 'mobx';
import { nanoid } from 'nanoid';

import { hasValue } from 'src/packages/utils/has-value';

import type { IControl, TLabelView, TSimpleLabelView } from '../../types';

export class LabelField implements IControl<unknown> {
  readonly id = nanoid(16);
  readonly view: TLabelView | TSimpleLabelView;
  readonly fieldId: string;
  readonly attrName: string | null;
  readonly attrKey: string | null;
  readonly initialValue: unknown;
  readonly isSimpleLabel: boolean;

  @observable value: unknown = null;
  @observable displayValue: string | null = null;
  @observable isEnteringBlocked = false;
  @observable isDisabled = false;
  @observable error: string | null = null;
  @observable isLoading = false;
  @observable isRequired = false;

  constructor(
    isSimple: boolean,
    view: TLabelView | TSimpleLabelView,
    attrName: string | null,
    attrKey: string | null,
    fieldId: string,
    initialValue?: unknown,
  ) {
    this.initialValue = initialValue ?? null;
    this.view = view;
    this.fieldId = fieldId;
    this.attrName = attrName;
    this.attrKey = attrKey;
    this.isSimpleLabel = isSimple;

    if (hasValue(initialValue)) {
      this.value = initialValue;
    }

    makeObservable(this);
  }

  @computed
  get hasChanges(): boolean {
    if (!this.attrName) {
      return false;
    }

    if (Array.isArray(this.value) && Array.isArray(this.initialValue)) {
      if (this.value.length !== this.initialValue.length) {
        return true;
      }

      for (let i = 0; i < this.value.length; i++) {
        if (this.value[i] !== this.initialValue[i]) {
          return true;
        }
      }

      return false;
    }

    return this.value !== this.initialValue;
  }

  @action.bound
  setValue(value: unknown): void {
    if (!hasValue(value)) {
      this.value = null;
      return;
    }

    this.value = value;
  }

  @action.bound
  setDisplayValue(value: string | null): void {
    if (!hasValue(value)) {
      this.value = null;
      return;
    }

    this.displayValue = value;
  }

  @action.bound
  setIsRequired(isRequired: boolean): void {
    this.isRequired = isRequired;
  }

  @action.bound
  setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  @action.bound
  setError(error: string | null): void {
    this.error = error;
  }

  @action.bound
  setIsDisabled(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  @action.bound
  setIsEnteringBlocked(isEnteringBlocked: boolean): void {
    this.isEnteringBlocked = isEnteringBlocked;
  }
}
