import { requireService } from '@profgeosoft/di-ez';
import { Switch } from '@profgeosoft-ui/react';
import clsx from 'clsx';

import { wrap } from 'src/packages/mobx-di/wrap';
import { PRIMARY_LANG, SECONDARY_LANG } from 'src/services/localization-service.ts/localization.consts';

import styles from '../switchers/switchers.module.scss';

const LanguageSwitcher = wrap(function LanguageSwitcher() {
  const localizationService = requireService('localizationService');

  const isLeftNodeActive = localizationService.language !== SECONDARY_LANG;

  const handleSwitcherChange = (value: boolean) => {
    if (!value) {
      localizationService.setLocale(PRIMARY_LANG);
    } else {
      localizationService.setLocale(SECONDARY_LANG);
    }
  };

  return (
    <div>
      <Switch
        size="m"
        checked={localizationService.language === SECONDARY_LANG}
        onChange={handleSwitcherChange}
        containerClassName={styles.switchContainer}
        className={styles.switchClassname}
        knobClassName={clsx(styles.knob, !isLeftNodeActive && styles.activeLeft)}
        leftNode={
          <p className={clsx(styles.text, isLeftNodeActive && styles.activeText)}>{localizationService.languages[0]}</p>
        }
        rightNode={
          <p className={clsx(styles.text, !isLeftNodeActive && styles.activeText)}>
            {localizationService.languages[1]}
          </p>
        }
      />
    </div>
  );
});

export default LanguageSwitcher;
