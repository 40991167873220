import { Loader } from '@profgeosoft-ui/react';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import { ModelsList } from 'src/features/models-list';
import { wrap } from 'src/packages/mobx-di/wrap';
import { Directories } from 'src/pages/directories';
import { DirectoryPage, Directory } from 'src/pages/directory';

export const Router = wrap(function Router() {
  return (
    <RouterProvider
      router={createBrowserRouter(
        createRoutesFromElements(
          <Route path="/" element={<Directories />}>
            <Route path=":directory" element={<DirectoryPage />}>
              <Route index element={<Directory />} />

              <Route path=":subDirectory" element={<ModelsList />}>
                <Route path=":modelId" element={<Directory />} />
                <Route index element={<Loader />} />
              </Route>
            </Route>
          </Route>,
        ),
      )}
    />
  );
});
