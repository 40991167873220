import { connect as connect } from '@profgeosoft/di-ez';
import { observer } from 'mobx-react-lite';

import type { ReactElement } from 'react';

export const wrap = <TProps, TRef = undefined>(
  renderFn: (props: TProps, ref: TRef) => ReactElement | null,
): typeof renderFn => {
  const fnName = renderFn.name;
  const x: Record<string, typeof renderFn> = {
    // eslint-disable-next-line mobx/no-anonymous-observer
    [fnName]: observer(connect(renderFn)),
  };

  return x[fnName];
};
