import { requireService } from '@profgeosoft/di-ez';
import { Button, Drawer, Loader } from '@profgeosoft-ui/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { wrap } from 'src/packages/mobx-di/wrap';

import type { AdditionalDirectoryService } from 'src/services/directory-service';

import { Form } from './features/form';

import styles from './directory-form-sidebar.module.scss';

type Props = {
  directory: AdditionalDirectoryService;
  onSave: VoidFunction;
  onCancel: VoidFunction;
};

export const DirectoryFormSidebar = wrap<Props>(function DirectoryFormSidebar({ directory, onSave, onCancel }) {
  const { t } = useTranslation();
  const directoriesList = requireService('directoriesListService');

  const directoryTitleKey = directoriesList.directoriesMap.get(directory.directoryName)?.title;
  const directoryTitle = directoryTitleKey ? t(`labels:${directoryTitleKey}.label`) : '';

  useEffect(() => {
    return directory.init();
  }, [directory]);

  return (
    <Drawer open className={styles.drawer} title={directoryTitle} closeIcon={null} portalClassName={styles.portal}>
      <div className={styles.contentWrapper}>
        {(directory.isDataFetching || directory.isAdditionalDirectoriesFetching) && <Loader />}

        {directory.record && <Form directory={directory} record={directory.record} />}

        <div className={styles.buttonsWrapper}>
          <Button className={styles.button} variant="danger" onClick={onCancel}>
            {t('common:cancel')}
          </Button>

          <Button className={styles.button} variant="success" onClick={onSave}>
            {t('common:save')}
          </Button>
        </div>
      </div>
    </Drawer>
  );
});
