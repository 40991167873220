import { Select } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { reaction } from 'mobx';
import { useCallback, useEffect, useState, type MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';

import { ComboboxDropdown } from 'src/components/combobox-dropdown';
import { wrap } from 'src/packages/mobx-di/wrap';
import { hasValue } from 'src/packages/utils/has-value';

import type { RefSelectProps } from '@profgeosoft-ui/react';
import type { TOption } from 'src/packages/types';
import type { ComboboxField } from 'src/services/directory-service/entities/controls/combobox-field';
import type { OptionsService } from 'src/services/directory-service/options-service';
import type { IControl, TComboBoxView } from 'src/services/directory-service/types';

import styles from './combobox.module.scss';

type Props = {
  control: ComboboxField;
  controlView: TComboBoxView;
  optionsService: OptionsService;
  disabled?: boolean;
  isLoading?: boolean;
  className?: string;
  innerRef?: MutableRefObject<RefSelectProps | null>;
  onChange(control: IControl, value: unknown): void;
  loadOptionsFn: (signal?: AbortSignal) => Promise<TOption[]>;
  onBlur?(control: IControl): void;
  onCreateNewRecord?(): void;
};

export const ComboboxComponent = wrap<Props>(function ComboboxComponent({
  innerRef,
  control,
  controlView,
  optionsService,
  className,
  onCreateNewRecord,
  onChange,
  loadOptionsFn,
  onBlur,
}) {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [internalOptions, setInternalOptions] = useState<TOption[]>([]);

  const actualOption = internalOptions.find((opt) => opt.value === control.value);

  const value = actualOption
    ? actualOption
    : optionsService.archivedOptions[control.fieldId]?.find((opt) => opt.value === control.value) ?? null;

  const loadOptions = useCallback((): VoidFunction => {
    const abortController = new AbortController();

    const load = async () => {
      control.setIsLoading(true);
      const options = await loadOptionsFn(abortController.signal);
      control.setIsLoading(false);
      setInternalOptions(options);
    };

    load();

    return () => abortController.abort();
  }, [control, loadOptionsFn]);

  const handleChange = (value: number | null | TOption) => {
    if (!hasValue(value)) {
      onChange(control, null);
      return;
    }

    if (typeof value === 'number') {
      onChange(control, value);
      return;
    }

    onChange(control, value.value);
  };

  const handleBlur = () => {
    onBlur?.(control);
  };

  useEffect(() => {
    if (!controlView.refQuery) {
      return;
    }

    return loadOptions();
  }, [controlView.refQuery, loadOptions]);

  useEffect(() => {
    return reaction(
      () => optionsService.options[control.fieldId] ?? [],
      (options) => setInternalOptions(options),
      { fireImmediately: true },
    );
  }, [control.fieldId, optionsService.options]);

  const isOpened = isOpen && !control.isLoading && !control.isDisabled && !control.isEnteringBlocked;

  return (
    <Select<TOption | null>
      ref={innerRef}
      open={isOpened}
      value={value}
      options={internalOptions}
      dropdownMatchSelectWidth={false}
      className={clsx(styles.combobox, className)}
      allowClear
      loading={control.isLoading}
      status={control.error ? 'error' : undefined}
      disabled={control.isDisabled || control.isEnteringBlocked}
      placeholder={t('common:placeholders.chooseValue')}
      onDropdownVisibleChange={(open) => setIsOpen(open)}
      dropdownRender={
        onCreateNewRecord
          ? (menu) => (
              <ComboboxDropdown onButtonClick={onCreateNewRecord} onClose={() => setIsOpen(false)}>
                {menu}
              </ComboboxDropdown>
            )
          : undefined
      }
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
});
