import { ScopeDIProvider } from '@profgeosoft/di-ez';
import { ConfigProvider, LicenseInfo, Loader, NotificationContainer, ru, en } from '@profgeosoft-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { agent } from 'src/api/agent';
import { VITE_MUI_KEY } from 'src/api/consts';
import { wrap } from 'src/packages/mobx-di/wrap';
import { auth } from 'src/services/auth-service/auth';
import { UserService, UserServiceNS } from 'src/services/auth-service/user-service';
import { DirectoriesListService } from 'src/services/directories-list-service/directories-list-service';
import { DirectoriesStorageService } from 'src/services/directories-storage-service';
import { LocalizationService } from 'src/services/localization-service.ts/localization-service';
import { NotificationsService } from 'src/services/notifications-service';
import { ThemeService } from 'src/services/theme-service';
import { UserSettingsService } from 'src/services/user-settings-service/user-settings-service';
import { ViewsService } from 'src/services/views-service';

import type { TViewTypes } from './types';
import type { IUserService } from 'src/services/auth-service/user-service';
import type { IDirectoriesStorage } from 'src/services/directories-storage-service/directories-storage-service';
import type { INotificationsService } from 'src/services/notifications-service';
import type { IThemeService } from 'src/services/theme-service';
import type { IViewService } from 'src/services/views-service';

import { Router } from './router';

import 'src/styles/index.scss';

LicenseInfo.setLicenseKey(VITE_MUI_KEY);

export const App = wrap(function App() {
  const [isInitiated, setIsInitiated] = useState(false);
  const [themeService] = useState(() => new ThemeService());
  const [notificationsService] = useState(() => new NotificationsService());
  const [directoriesStorageService] = useState(() => new DirectoriesStorageService(agent, notificationsService));
  const [userService] = useState(() => new UserService());
  const [localizationService] = useState(() => new LocalizationService(agent, notificationsService));
  const [userSettings] = useState(() => new UserSettingsService(agent, userService, notificationsService));
  const [directoriesListService] = useState(
    () => new DirectoriesListService(agent, notificationsService, userSettings),
  );

  const init = useCallback(() => {
    return {
      themeService: themeService,
      notificationsService: notificationsService,
      localizationService: localizationService,
      directoriesListService: directoriesListService,
      viewsService: new ViewsService<TViewTypes>(agent),
      directoriesStorage: directoriesStorageService,
      userService,
      userSettings,
    };
  }, [
    themeService,
    notificationsService,
    localizationService,
    directoriesListService,
    directoriesStorageService,
    userService,
    userSettings,
  ]);

  const { theme } = themeService;
  const { t } = useTranslation();

  useEffect(() => {
    if (!isInitiated) {
      auth()
        .then(() => {
          if (!UserServiceNS.getIsAuthenticated()) {
            return UserServiceNS.login();
          }

          return;
        })
        .then(() => localizationService.init())
        .catch((error) => console.error(error))
        .finally(() => {
          userService.init();
          setIsInitiated(true);
        });
    }
  }, [localizationService, isInitiated, userService]);

  const container = document.querySelector('body');

  return (
    <ConfigProvider
      config={{ theme, size: 's', style: 'bordered', localization: localizationService.language === 'ru' ? ru : en }}
      externalElement={container ?? undefined}
    >
      {isInitiated ? (
        <ScopeDIProvider init={init}>
          <Router />
        </ScopeDIProvider>
      ) : (
        <Loader size="l" />
      )}
      <NotificationContainer />
    </ConfigProvider>
  );
});

declare module '@profgeosoft/di-ez' {
  export interface ServicesCollectionMap {
    themeService: IThemeService;
    userService: IUserService;
    notificationsService: INotificationsService;
    directoriesListService: DirectoriesListService;
    localizationService: LocalizationService;
    viewsService: IViewService<TViewTypes>;
    directoriesStorage: IDirectoriesStorage;
  }
}
