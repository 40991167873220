import { CopyAltLight, Paste, TableLight } from '@profgeosoft-ui/icons';
import { Button } from '@profgeosoft-ui/react';
import { useTranslation } from 'react-i18next';

import { wrap } from 'src/packages/mobx-di/wrap';

import type { MainDirectoryService } from 'src/services/directory-service';

import styles from '../directory-page-header.module.scss';

type Props = {
  isEditMode: boolean;
  isOpenExemplarsButtonVisible: boolean;
  directory: MainDirectoryService | null;
  onOpenExemplarsTable: VoidFunction;
};

export const TableStringControls = wrap<Props>(function TableStringControls({
  isEditMode,
  isOpenExemplarsButtonVisible,
  directory,
  onOpenExemplarsTable,
}) {
  const { t } = useTranslation('appbar');

  if (!isEditMode && isOpenExemplarsButtonVisible) {
    return (
      <Button variant="flat" className={styles.buttonFlat} onClick={onOpenExemplarsTable}>
        <TableLight />
        {t('appbar.instanceTable')}
      </Button>
    );
  }

  if (!isEditMode) {
    return null;
  }

  return (
    <>
      <Button
        variant="flat"
        className={styles.buttonFlat}
        disabled={!directory?.directoryViewMediator?.isCopyAvailable}
        onClick={directory?.directoryViewMediator?.copySelectedRowsToClipboard}
      >
        <CopyAltLight />
        {t('appbar.copyAll')}
      </Button>
      <Button
        variant="flat"
        className={styles.buttonFlat}
        onClick={() =>
          directory?.directoryViewMediator?.pasteData(directory?.tableSettingsManager?.selectedCellData || undefined)
        }
      >
        <Paste />
        {t('appbar.paste')}
      </Button>
    </>
  );
});
