import { requireService } from '@profgeosoft/di-ez';

import { wrap } from 'src/packages/mobx-di/wrap';

import { MAIN_LOGO, MAIN_LOGO_DARK } from './logo.consts';

import styles from './logo.module.scss';

export const Logo = wrap(function Logo() {
  const { theme } = requireService('themeService');

  const isDarkTheme = theme === 'dark';

  return (
    <div className={styles.logoWrapper}>
      <img src={isDarkTheme ? MAIN_LOGO_DARK : MAIN_LOGO} alt="Logo" />
    </div>
  );
});
