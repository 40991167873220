import { YearPicker } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { wrap } from 'src/packages/mobx-di/wrap';

import type { YearOnlyPickerField } from 'src/services/directory-service/entities/controls/year-only-picker';

import styles from './year-only-picker-component.module.scss';

type Props = {
  control: YearOnlyPickerField;
  className?: string;
  onChange?(control: YearOnlyPickerField, value: number | null): void;
  onBlur?(control: YearOnlyPickerField): void;
};

export const YearOnlyPickerComponent = wrap<Props>(function YearOnlyPickerComponent({
  control,
  className,
  onChange,
  onBlur,
}) {
  const { t } = useTranslation();

  const handleChange = useCallback((value: number | null) => onChange?.(control, value), [control, onChange]);

  const handleBlur = useCallback(() => onBlur?.(control), [control, onBlur]);

  return (
    <YearPicker
      value={control.value}
      disabled={control.isDisabled || control.isEnteringBlocked}
      status={control.error ? 'error' : undefined}
      placeholder={t('common:placeholders.chooseYear')}
      className={clsx(styles.field, className)}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
});
