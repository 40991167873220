import { requireService } from '@profgeosoft/di-ez';
import { useTranslation } from 'react-i18next';

import { DiscardChangesModal } from 'src/components/discard-changes-modal';
import { wrap } from 'src/packages/mobx-di/wrap';

import type { DirectoryPageHeaderService } from 'src/services/directory-page-header-service';
import type { MainDirectoryService } from 'src/services/directory-service';

import { EditModeControls } from './edit-mode-controls/edit-mode-controls';
import { GeneralButtons } from './general-buttons';
import { GeneralControls } from './general-controls';
import { TableStringControls } from './table-string-controls/table-string-controls';

import styles from './directory-page-header.module.scss';

type Props = {
  directoryPageHeaderService: DirectoryPageHeaderService;
  mainDirectory: MainDirectoryService | null;
  isOpenExemplarsButtonVisible: boolean;
  onChangeVisibleDirectory: (type: 'main' | 'dependent') => void;
};

export const DirectoryPageHeader = wrap<Props>(function DirectoryPageHeader({
  directoryPageHeaderService,
  mainDirectory,
  isOpenExemplarsButtonVisible,
  onChangeVisibleDirectory,
}) {
  const { t } = useTranslation('appbar');
  const directoryPage = requireService('directoryPage');

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.column}>
          {/* temp disabled */}
          {/* <SearchInput isEditMode={directoryPage.isEditing} />
          <LanguageSelect isEditMode={directoryPage.isEditing} /> */}
          <TableStringControls
            isEditMode={directoryPage.isEditing}
            isOpenExemplarsButtonVisible={isOpenExemplarsButtonVisible}
            directory={mainDirectory}
            onOpenExemplarsTable={() => onChangeVisibleDirectory('dependent')}
          />
        </div>

        {mainDirectory && (
          <div className={styles.column}>
            <GeneralControls directory={mainDirectory} controls={mainDirectory.generalControls} />
            <GeneralButtons directory={mainDirectory} />
            <EditModeControls
              directory={mainDirectory}
              onTableSettingsClick={() =>
                mainDirectory.tableSettingsManager?.setSettingsOpen(!mainDirectory.tableSettingsManager.isSettingsOpen)
              }
            />
          </div>
        )}
      </div>
      <DiscardChangesModal
        isOpen={directoryPageHeaderService.isConfirmationModalOpen}
        modalTitle={t('appbar:discardChahgesModal.title')}
        modalText={t('appbar:discardChahgesModal.text')}
        okText={t('appbar:discardChahgesModal.discard')}
        cancelText={t('appbar:discardChahgesModal.cancel')}
        onCancel={directoryPageHeaderService.onConfirmationOk}
        onOk={directoryPageHeaderService.onConfirmationCancel}
      />
    </>
  );
});
