import { wrap } from 'src/packages/mobx-di/wrap';

import type { MainDirectoryService } from 'src/services/directory-service';

import { CopyToAnotherRoom } from '../copy-to-another-room';

type Props = {
  directory: MainDirectoryService;
};

export const GeneralButtons = wrap<Props>(function GeneralButtons({ directory }) {
  const buttonsView = directory.view.controls?.buttons;

  if (!buttonsView || !buttonsView.length || directory.status.type !== 'ready') {
    return null;
  }

  return (
    <>
      {buttonsView.map((buttonView) => {
        // Если появятся новые виды кнопок, то нужно будет добавить во вьюху свойство типа кнопки и свитчится на основе него
        switch (buttonView.fieldId) {
          case 'copyData': {
            return <CopyToAnotherRoom key={buttonView.fieldId} view={buttonView} directory={directory} />;
          }

          default:
            return null;
        }
      })}
    </>
  );
});
