import { hasValue } from 'src/packages/utils/has-value';

import type { TConcatinationRule } from '../mappers';
import type { ObservableMap } from 'mobx';
import type { IDirectoriesStorage } from 'src/services/directories-storage-service/directories-storage-service';
import type { IControl } from 'src/services/directory-service/types';

import { handleValueSourceRule } from './handle-value-source-rule';

export const handleConcatinationRule = (
  directories: IDirectoriesStorage,
  controlsMap: ObservableMap<string, IControl>,
  rule: TConcatinationRule,
): { value: string | null } => {
  const value = handleValueSourceRule(directories, controlsMap, rule.ruleView.value.sources)?.value;

  if (!Array.isArray(value) && hasValue(value)) {
    console.error('value is not array', value);
    return { value: null };
  }

  if (!value) {
    return { value: null };
  }

  return { value: value.join(rule.ruleView.delimiter) };
};
