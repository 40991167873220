import { Input } from '@profgeosoft-ui/react';
import { useTranslation } from 'react-i18next';

import { wrap } from 'src/packages/mobx-di/wrap';

import type { ChangeEventHandler, MutableRefObject } from 'react';
import type { StringField } from 'src/services/directory-service/entities/controls/string-field';
import type { IControl } from 'src/services/directory-service/types';

type Props = {
  control: StringField;
  disabled?: boolean;
  isLoading?: boolean;
  className?: string;
  innerRef?: MutableRefObject<HTMLInputElement | null>;
  onChange(control: IControl, value: unknown): void;
  onBlur?(control: IControl): void;
};

export const StringInput = wrap<Props>(function StringInput({ innerRef, control, className, onChange, onBlur }) {
  const { t } = useTranslation();

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value;

    onChange(control, value === '' ? null : value);
  };

  const onClear = () => {
    onChange(control, null);
  };

  const handleBlur = () => {
    onBlur?.(control);
  };

  return (
    <Input
      ref={innerRef}
      status={control.error ? 'error' : undefined}
      className={className}
      value={control.value}
      loading={control.isLoading}
      placeholder={t('common:placeholders.enterValue')}
      disabled={control.isDisabled || control.isEnteringBlocked}
      onChange={handleChange}
      onClear={onClear}
      onBlur={handleBlur}
    />
  );
});
