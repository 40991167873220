import { hasValue } from 'src/packages/utils/has-value';
import { StringField } from 'src/services/directory-service/entities/controls/string-field';

import type { TRestrictionsRule } from '../mappers';
import type { IControl } from 'src/services/directory-service/types';

export const handleRestrictionsRule = (
  control: IControl,
  rule: TRestrictionsRule,
  value: unknown,
): { value: unknown; validation: string[] } | null => {
  if (control instanceof StringField) {
    let editedValue = value;

    if (
      hasValue(rule.ruleView.maxLength) &&
      typeof editedValue === 'string' &&
      editedValue.length > rule.ruleView.maxLength
    ) {
      editedValue = editedValue.slice(0, rule.ruleView.maxLength);
    }

    return { value: editedValue, validation: [] };
  }

  // TODO: здесь должна быть обработка min/max правил для числовых контролов

  return null;
};
