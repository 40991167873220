function getEnvVariable(key: string): string | undefined {
  return window._env_?.[key] || import.meta.env[key];
}

function getRequiredEnvVariable(key: string): string {
  const value = window._env_?.[key] || import.meta.env[key];

  if (typeof value === 'undefined') {
    console.error(key);
    throw new Error('env variable is not set');
  }

  return value;
}

export const API_URL = getRequiredEnvVariable('VITE_BACKEND_API_URL') ?? '';
export const AUTH_API_URL = getRequiredEnvVariable('VITE_AUTH_API_URL') ?? '';
export const VITE_MUI_KEY =
  '41e30292e843764d4d525ef823c50677Tz04MTE4NixFPTE3MzUyNjk2MzgwMDAsUz1wcmVtaXVtLExNPXBlcnBldHVhbCxLVj0y';
export const GOSTORAGE_API_URL = getRequiredEnvVariable('VITE_GO_STORAGE_URL') ?? '';
