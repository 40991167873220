import clsx from 'clsx';

import { wrap } from 'src/packages/mobx-di/wrap';
import { SECONDARY_LANG } from 'src/services/localization-service.ts/localization.consts';

import LanguageSwitcher from '../language-switcher/language-switcher';
import ThemeSwitcher from '../theme-switcher/theme-switcher';

import styles from './switchers.module.scss';

type Props = {
  isOpened: boolean;
};

const isSecondLanguagePresented = !!SECONDARY_LANG;

export const Switchers = wrap(function Switchers({ isOpened }: Props) {
  return (
    <div className={styles.wrapper}>
      {isSecondLanguagePresented && (
        <div className={clsx(styles.switcherWrapper, !isOpened && styles.switcherWrapper__compact)}>
          <LanguageSwitcher />
        </div>
      )}
      <div className={clsx(styles.switcherWrapper, !isOpened && styles.switcherWrapper__compact)}>
        <ThemeSwitcher />
      </div>
    </div>
  );
});
