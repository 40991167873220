import { useSortable } from '@dnd-kit/sortable';
import { EyeLight, MoveCustom, PinLightLeft, ViewHideLight } from '@profgeosoft-ui/icons';
import { Button, Switch, Tooltip } from '@profgeosoft-ui/react';
import { useTranslation } from 'react-i18next';

import { wrap } from 'src/packages/mobx-di/wrap';

import styles from './display-settings.module.scss';

type Props = {
  field: string;
  isPinned: boolean;
  isVisible: boolean;
  isVisibilitySwitcherDisabled: boolean;
  title?: string;
  onColumnVisibilityChange(column: string, value: boolean): void;
  unpinColumn(field: string): void;
};

export const DisplaySettingItem = wrap<Props>(function DisplaySettingItem({
  field,
  title,
  isVisible,
  isPinned,
  onColumnVisibilityChange,
  unpinColumn,
  isVisibilitySwitcherDisabled,
}) {
  const { t } = useTranslation();

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: field });

  const style = {
    transform: transform ? `translate(0, ${transform.y}px) scaleX(1) scaleY(1)` : undefined,
    transition,
  };

  return (
    <div ref={setNodeRef} className={styles.settingItem} style={style}>
      <div className={styles.leftColumn}>
        {isPinned ? (
          <Tooltip title={t('displaySettings:pinAlarm')}>
            <Button variant="flat" {...listeners} {...attributes} disabled={true} className={styles.draggingButton}>
              <MoveCustom />
            </Button>
          </Tooltip>
        ) : (
          <Button variant="flat" {...listeners} {...attributes} className={styles.draggingButton}>
            <MoveCustom />
          </Button>
        )}

        <Switch
          leftNode={<ViewHideLight />}
          rightNode={<EyeLight />}
          checked={isVisible}
          disabled={isVisibilitySwitcherDisabled}
          onChange={(value) => onColumnVisibilityChange(field, value)}
        />
        <p>{title}</p>
      </div>
      {isPinned && (
        <Button variant="flat" className={styles.unpinButton} onClick={() => unpinColumn(field)}>
          <PinLightLeft />
        </Button>
      )}
    </div>
  );
});
