import { requireService } from '@profgeosoft/di-ez';
import { Button, Modal } from '@profgeosoft-ui/react';
import { useTranslation } from 'react-i18next';

import { wrap } from 'src/packages/mobx-di/wrap';

import DataWillEraseDark from './data-will-erase-dark.svg?react';
import DataWillEraseLight from './data-will-erase-light.svg?react';

import styles from './confirm-copy-modal.module.scss';

type Props = {
  isOpened: boolean;
  onCancel: VoidFunction;
  onApply: VoidFunction;
};

export const ConfirmCopyModal = wrap<Props>(function ConfirmCopyModal({ isOpened, onCancel, onApply }) {
  const { t } = useTranslation();
  const { theme } = requireService('themeService');

  return (
    <Modal open={isOpened} onClickOutside={null} closeIcon={null}>
      <div className={styles.wrapper}>
        <div className={styles.contentWrapper}>
          {theme === 'dark' ? (
            <DataWillEraseDark className={styles.img} />
          ) : (
            <DataWillEraseLight className={styles.img} />
          )}
          <div>
            <h3 className={styles.heading}>{t('directory:components.copyToAnotherRoom.continueCopying')}</h3>
            <p className={styles.infoText}>{t('directory:components.copyToAnotherRoom.eraseDataWhileCopyInfo')}</p>
          </div>
        </div>
        <div className={styles.buttonsWrapper}>
          <Button className={styles.button} variant="danger" onClick={onCancel}>
            {t('common:cancel')}
          </Button>
          <Button className={styles.button} variant="success" onClick={onApply}>
            {t('common:continue')}
          </Button>
        </div>
      </div>
    </Modal>
  );
});
