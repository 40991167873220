import { action, makeObservable, observable } from 'mobx';

export interface IThemeService {
  theme: TTheme;
  toggle(): void;
}

export type TTheme = 'dark' | 'light';

const checkTheme = (value: string | null): value is TTheme => {
  if (!value) return false;
  if (['light', 'dark'].includes(value)) return true;
  return false;
};

export class ThemeService implements IThemeService {
  @observable theme: TTheme;

  constructor() {
    const storedTheme = localStorage.getItem('theme');
    const initTheme = checkTheme(storedTheme) ? storedTheme : 'dark';
    this.theme = initTheme;

    makeObservable(this);
  }

  private setTheme(theme: TTheme) {
    this.theme = theme;
    localStorage.setItem('theme', theme);
  }

  @action.bound
  toggle() {
    this.setTheme(this.theme === 'dark' ? 'light' : 'dark');
  }
}
